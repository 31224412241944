import React, { useContext, useEffect, useState } from "react";
import Page from "@/layouts/Page";
import LoaderAnimation from "@/components/Lottie/loader";

import { AppContext } from "@/context/createContext";
import { bellaToken, polygon, ethereum, customers } from "@/config/svg";
import { useNavigate } from 'react-router-dom';
import { communityItem } from "@/services/index";

import LazyLoad from "react-lazyload";
import FadeIn from "react-fade-in";
import routes from "@/config/routes";

const CommunityTokensList: React.FC = () => {

  let navigate = useNavigate();
  const { state, selectCommunityFeed, addCommunityTokenList, disconnect, resetError, setError } = useContext(AppContext);
  const [communityTokenList, setSelectedCommunityTokenList] = useState<any>([]);
  const [selectedCommunityFeed, setSelectedCommunityFeed] = useState<any>(null);
  const  [loader, stopAnimation] = useState(false);

  useEffect(() => {
    document.body.className = "communityTokensList-page";
    return () => {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {

    // end condition
    if (!selectedCommunityFeed) {
      // check if I have the info saved on context
      if(state?.selectedCommunityFeed){
        setSelectedCommunityFeed(state?.selectedCommunityFeed);
      } else {
        const getSelectedCommunityFeed = localStorage.getItem("selectedCommunityFeed");
        if (getSelectedCommunityFeed && getSelectedCommunityFeed !== "") {
          selectCommunityFeed(JSON.parse(getSelectedCommunityFeed), false); // save info again on state
        }
      }
    }

  }, [selectCommunityFeed, selectedCommunityFeed, state?.selectedCommunityFeed]);

  useEffect(() => {

    // end condition
    if (communityTokenList && communityTokenList.length === 0) {

      // check if I have the info saved on context
      if (state?.communityTokenList && state?.communityTokenList?.length > 0) {
        setSelectedCommunityTokenList(state?.communityTokenList);
        stopAnimation(true);
      } else {

        const getBellaUserId = localStorage.getItem("bellaUserId");
        const getAccessToken = localStorage.getItem("accessToken");
        if (getBellaUserId && getBellaUserId !== "" && getAccessToken && getAccessToken !== "" 
          && selectedCommunityFeed) {
            console.log({selectedCommunityFeed});
            
          const {communityId} = selectedCommunityFeed;
          if(communityId){
          
          communityItem(getBellaUserId, getAccessToken, communityId, false)
            .then(async (res: any) => {
              if(res?.response?.status === 401){
                disconnect();
                //window.location.reload();
              }
              else if (res?.name === 'AxiosError'){
                const error = {
                  visible: true,
                  text: res?.message,
                }
                setError(error);
              }
              else if(res?.data){
                addCommunityTokenList(res?.data?.results, true);
                resetError(null);
              }
            })
            .catch((error) => {
              // ERROR
              console.log(error);
            });}
            else{
              navigate(routes.communitySelectedFeed)
            }


        }
      }
    }

  }, [addCommunityTokenList, communityTokenList, disconnect, navigate, resetError, selectedCommunityFeed, setError, state]);

  const currencyImage = (currency: string) => {
    return (currency === "POLYGON") ? polygon : ethereum ;
  };

  const handleSelectToken = async (channelId: string) => {
    if(channelId !== null && channelId !== ""){
      //selectCommunityToken(channelId);
      navigate("/tokens/" + channelId);
    }
  };

  return (
    <>
    {!loader || selectedCommunityFeed==null || communityTokenList === null ? (
      <section className="token-section h-100">
        <div className="container h-100 d-flex justify-content-center align-items-center">
          <LoaderAnimation height={100} width={100} />
        </div>
      </section>
    ) : (
      <>
      <Page>
        <section className="token-section h-100">
          <div className="container h-100">
            <div className="row h-100">
              <div className="">
                <div className="item-block">
                  <div className="custom-mt-55 text-center">
                    <h2 className="rainbow-title-page custom-mb-40">AVAILABLE OFFERS</h2>
                    <div className="container-list custom-pl-30 custom-pr-30">
                      {communityTokenList?.length > 0 &&
                        communityTokenList.map((item: any, index: number) => (
                        <LazyLoad height={200} once key={index.toString()}>
                          <FadeIn transitionDuration={800}>
                            <div 
                              className={
                                index & 1
                                  ? "isOdd"
                                  : "isEven"
                              }
                            >
                              <div className="block">
                                <div className="row d-flex align-items-center">
                                  <div className="col-5">
                                    <div className="romboblock position-relative" style={{ backgroundImage: `url(${bellaToken})` }}>
                                      <img className="position-absolute" src={item?.iconUrl} alt="icon" />
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <p className="light-text text-left">{item?.description}</p>
                                    {index & 1 ? (
                                      <div className="custom-mt-20 d-flex align-items-center justify-content-end">
                                        <p className="bold-text text-left">
                                          {item?.tokenPrice} 
                                          <img 
                                            className={
                                              item?.tokenPrice
                                                ? "custom-mr-10 icon-currency"
                                                : "hide"
                                            }
                                          src={currencyImage(item?.chain)} alt="icon" />
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="custom-mt-20 d-flex align-items-center justify-content-start">
                                        <p className="bold-text text-left">
                                          <img className={
                                              item?.tokenPrice
                                                ? "custom-mr-10 icon-currency"
                                                : "hide"
                                            } src={currencyImage(item?.chain)} alt="icon" />
                                          {item?.tokenPrice} 
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row d-flex align-items-center custom-mt-20">
                                  <div className="col-5">
                                    {index & 1 ? (
                                      <div className="custom-ml-30 d-flex align-items-center justify-content-end">
                                        <p className="light-text text-left">
                                          {item?.numberOfParticipants} 
                                          <img className="custom-mr-10" src={customers} alt="icon" />
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="custom-ml-10 d-flex align-items-center">
                                        <p className="light-text text-left">
                                        <img className="custom-mr-10" src={customers} alt="icon" />
                                          {item?.numberOfParticipants} 
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-7 button-block">
                                  {item?.tokensAvailable > 0 ? (
                                    <button className="custom-btn small-bnt" onClick={() => handleSelectToken(item?.channelId)}>
                                      <p className="btn-text">Participate</p>
                                    </button>
                                  ) : (
                                    <button className="custom-btn small-bnt" disabled>
                                      <p className="btn-text">Sold out</p>
                                    </button>
                                  )}
                                  </div>
                                </div>
                              </div>
                              <div className="separator custom-mt-45 custom-mb-50"></div>
                            </div>
                          </FadeIn>
                        </LazyLoad>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
      </>
    )}
    </>
  );
};

export default CommunityTokensList;