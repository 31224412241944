import React, { useContext, useEffect } from "react";
import RailPinkAnimation from "@/components/Lottie/railpink";
import LoaderAnimation from "@/components/Lottie/loader";
import Page from "@/layouts/Page";
import routes from "@/config/routes";
import { useNavigate } from "react-router-dom";
import { AppContext } from "@/context/createContext";

const Authenticate: React.FC = () => {

    let navigate = useNavigate();
    const { pubNubChatConnector, pubnubDestroy } = useContext(AppContext);

    useEffect(()=>{

        (async function() {
            if(pubNubChatConnector !== null) {  await pubnubDestroy(); }
            setTimeout(() => {
                // authomatic redirect to home
                navigate(routes.root);
            }, 3000);
        })();

    },[navigate, pubNubChatConnector, pubnubDestroy])

    useEffect(() => {
        document.body.className = "authenticate-page";
        return () => {
            document.body.className = "";
        };
    }, []);

    return (
        <>
            <Page>
                <section className=" h-100 ">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="custom-mt-55 d-flex flex-column justify-content-center align-items-center custom-mb-40 text-center">
                                <h1 className="rainbow-title-page custom-mb-10">YOU ARE ALL SET!</h1>
                                <LoaderAnimation height={87} width={87} />
                            </div>
                        </div>
                    </div>
                    <div className="lottie-rail-wrapper">
                        <RailPinkAnimation />
                    </div>
                </section>
            </Page>
        </>
    )
};

export default Authenticate;


