import { AppContext } from '@/context/createContext';
import React, { useContext } from "react";

const Error: React.FC = () => {
  const { state } = useContext(AppContext);

  return (
    <>
      {state?.error?.visible ?
        <div className='api-error'>
          <span>⚠ {state?.error?.text !== "" ? state?.error?.text : "ERROR"}</span>
        </div>
        : null}
    </>
  )
};

export default Error;


