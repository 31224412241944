import { createContext } from 'react'
import { AppState } from '@/interface/index'
import WalletConnect from "@walletconnect/client";
import Pubnub from 'pubnub';

export type AppContextProps = {
    state: AppState;
    connect: () => Promise<void>;
    disconnect: () => Promise<void>;
    checkChainAndNetwork: (chain:string, network:string) => boolean;
    walletConnector:WalletConnect;
    checkWalletConnection:( returnType:'object'|'boolean' ) => Promise<WalletConnect|boolean>;
    signMessage: ( item: any ) => Promise<string>;
    resetListState: () => any;
    resetCommunityTokenList: () => any;
    addBellaUser: ( item: any ) => any;
    addCommunityFeedList: (item: any ) => any;
    selectCommunityFeed: (item: any, saveOnLocal: boolean ) => any;
    addCommunityTokenList: (item: any, saveOnLocal: boolean ) => any; // selected Feed tokens
    addUserChannelTokenList: (item: any, saveOnLocal: boolean ) => any;
    selectCommunityToken: (item: any ) => any;
    addWalletConnectInfo: (item: any, saveOnLocal: boolean ) => any;
    isAuthenticate: () => Promise<boolean>;
    pubNubChatConnector: Pubnub;
    checkChatConnection: ( returnType:'object'|'boolean' ) => Promise<Pubnub|boolean>;
    pubnub: () => Promise<void>;
    pubnubDestroy: () => Promise<void>;
    addPublicCommunityInfo: (item: any, saveOnLocal: boolean ) => any;
    createProtobufTextMessage: (item: any) => Promise<any>;
    setInfoHistoryMessage: (item: any, channelId: string) => any;
    setError: (item: any) => void;
    resetError: (item: any) => void;
    resetState: () => any;
    addUserProfile: (item: any ) => any;
    addPrivateChannelsList:(item: any ) => any;
    addTokensList: (item: any ) => any;
    addTransactionsList: (item: any ) => any;
    addNftList: (item: any ) => any;
    
}

const AppContext = createContext<AppContextProps>({} as AppContextProps)

export { AppContext };

