import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "@/context/createContext";
import Page from "@/layouts/Page";
import routes from "@/config/routes";
import FadeIn from "react-fade-in";
import { useNavigate } from "react-router-dom";
import { deleteUser } from '@/services/index';
import LoaderAnimation from "@/components/Lottie/loader";

const DeleteAccount: React.FC = () => {

    let navigate = useNavigate();
    const { state, walletConnector, checkWalletConnection, connect, isAuthenticate, disconnect, resetState, resetError, setError } = useContext(AppContext);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const { pubNubChatConnector, pubnubDestroy } = useContext(AppContext);
    const [showPopup, setShowPopup] = useState(false);
    const  [loader, stopAnimation] = useState(true);


    useEffect(() => {
        document.body.className = "deleteaccount-page";
        return () => {
            document.body.className = "";
        };
    }, []);

    useEffect(() => {
        //check if I have a wallet connection
        if (walletConnector === null) {
            checkWalletConnection('boolean').then(async (isConnected) => {
                if (isConnected) {
                    await connect();
                } else {
                    // guest user - silent is golden
                }
            })
        }
        //if I have a wallet connection - logged user
        if (walletConnector !== null) {
            (async function () {
                if (await isAuthenticate()) {
                    setIsUserAuthenticated(true);
                }
                else {
                    console.log("disconnect wallet connect session");
                    disconnect(); //disconnect wallet connect session
                }
            })();
        }
    });

    const deleteThisUser = async () => {

        if(isUserAuthenticated){
            
            const bellaUserId = localStorage.getItem("bellaUserId");
            const accessToken = localStorage.getItem("accessToken");
            if (bellaUserId && bellaUserId !== "" && accessToken && accessToken !== "") {

                stopAnimation(false);
                deleteUser(state?.bellaUser?.bellaUserId, state?.bellaUser?.accessToken)
                    .then(async (res: any) => {
                        if(res?.response?.status === 401){
                            disconnect();
                            window.location.reload();
                        }
                        else if (res?.name === 'AxiosError'){
                            const error = {
                                visible: true,
                                text: res?.message,
                            }
                            setError(error);
                        }
                        else if(res?.status === 204){
                            resetError(null);
                            if(pubNubChatConnector !== null) { 
                                pubnubDestroy();
                            }
                            disconnect();
                            localStorage.setItem("bellaUserId", "");
                            localStorage.setItem("accessToken", "");
                            localStorage.setItem("messaging", "");
                            localStorage.setItem("refreshToken", "");
                            localStorage.setItem("selectedCommunityFeed", "");
                            localStorage.setItem("communityTokenList", "");
                            localStorage.setItem("userChannelTokenList", "");
                            /*   resetState();*/
                            setTimeout(() => {
                                console.log('start redirect');
                                resetState();
                                navigate(routes.root);
                            }, 2000);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }else {
                console.log("ERROR DATA NOT FOUND");
            }
        }
    };

    return (
        <>
        {!loader ? (
            <div className="container h-100 d-flex justify-content-center align-items-center">
              <LoaderAnimation height={100} width={100} />
            </div>
          ) : (
            <>
            <Page>
                <section className="d-flex flex-column flex-grow-1 h-100">
                    <div className="container flex-grow-1 h-100">
                        <div className="row h-100">
                            <div className="custom-mt-55 d-flex flex-column justify-content-center align-items-center text-center">
                                <h1 className="rainbow-title-page custom-mb-82 ">ACCOUNT DELETION</h1>
                                <p className="grey regular-text-small text-center custom-pr-35 custom-pl-35">Are you sure?<br />&nbsp;<br />

                                    Deleting your account will remove all your posted messages as well as all your activity history in the app.<br />&nbsp;<br />

                                    Deleting your Bella account will not affect your connected wallet nor your wallet holdings, including any Bella tokens you may own.</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center  text-center">
                        <button className="cursor-pointer custom-btn small-bnt custom-mt-30 custom-mb-30" onClick={() => setShowPopup(true)}>
                            <p className="btn-text ">Delete Account</p>
                        </button>
                    </div>

                </section>
                {showPopup ?
                    <FadeIn transitionDuration={600}>
                        <div className="popup-overlay">
                            <div className="popup-inner">
                                <div className="popup-content text-center">
                                    <p className="medium-text custom-mb-20 custom-pt-10">Confirm deletion</p>
                                    <p className=" regular-text-small">One last "are you sure".<br />&nbsp;<br />

                                        Are you sure?<br />&nbsp;<br />

                                        You can't get your message history or activity back if you continue.</p>
                                    <button className="custom-btn small-bnt custom-mt-40" onClick={() => navigate(routes.profile)}>
                                        <p className="btn-text ">I changed my mind!</p>
                                    </button>
                                    <button className="black-btn super-small-bnt custom-mt-15" onClick={() => deleteThisUser()}>
                                        <p className="btn-text ">Delete account</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                    : <></>}
            </Page>
            </>
            )}
            </>
          );
        };

export default DeleteAccount;


