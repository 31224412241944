import axios from 'axios';

export const guestLogin = async () => {
    if (!process.env.REACT_APP_AUTH_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    try {
        const response = await axios
            .post(`${process.env.REACT_APP_AUTH_BASE_URL}/api/v1/unauth/users/access`, "", {
                headers: {"device-id": "web-app"}
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const communityList = async (userId: string | null, accessToken: string | null) => {
    if (!process.env.REACT_APP_API_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null) {
        console.error('No userId or accessToken specified for this api');
        return ;
    }
    try {
        const response = await axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${userId}/communities/feed`, {
                headers: {"device-id": "web-app",
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const communityItem = async (userId: string | null, accessToken: string | null, communityId: string | null, isMember: boolean) => {

    if (!process.env.REACT_APP_API_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null || communityId === null ||
    userId === "undefined" || accessToken === "undefined" || communityId === "undefined") {
        console.error('No userId or accessToken or community feed specified for this api');
        return ;
    }
    try {
        const response = await axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${userId}/communities/${communityId}/channels?fieldsToInclude=name,iconUrl,profileImageUrl,description,creatorName,creationTime,tokenPrice,tokensAvailable,tokensTotal,numberUnreadMessages,numberOfParticipants,network,chain&isMember=${isMember}`, {
                headers: {"device-id": "web-app",
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const communityToken = async (userId: string | null, accessToken: string | null, channelId: string | null) => {

    if (!process.env.REACT_APP_API_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null || channelId === null ||
    userId === "undefined" || accessToken === "undefined" || channelId === "undefined") {
        console.error('No userId or accessToken or channel tokens specified for this api');
        return ;
    }
    try {
        const response = await axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${userId}/channels/${channelId}`, {
                headers: {"device-id": "web-app",
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const bridgeUrl = async () => {   
    
    if (!process.env.REACT_APP_AUTH_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    try {
        const response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/api/v1/clients/config`)
        return {url:response?.data?.walletConnect?.bridges?.fallback}
    } catch (error) {
        console.error('Failed to load API!', error)
        return {error}
    } 
}

export const nonceOnbording = async (userId: string | null, accessToken: string | null, walletAddr: string) => {
    if (!process.env.REACT_APP_AUTH_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null || walletAddr === null ||
        userId === "undefined" || accessToken === "undefined" || walletAddr === "undefined") {
            console.error('No userId or accessToken specified for this api');
            return ;
        }
    try {

        const payload = {
            walletAddress: walletAddr
        }

        const response = await axios
            .post(`${process.env.REACT_APP_AUTH_BASE_URL}/api/v1/users/nonce`, payload, {
                headers: {"device-id": "web-app",
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const authentication = async (userId: string | null, accessToken: string | null, 
        nonce: string, walletAddress: string, signedNonce: string) => {
    if (!process.env.REACT_APP_AUTH_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null || userId === "undefined" || accessToken === "undefined") {
        console.error('No userId or accessToken specified for this api');
        return ;
    }
    if (nonce === "" || walletAddress === "" || signedNonce === "") {
        console.error('No payload for this api');
        return ;
    }

    try {

        const payload = {
            rawNonce: nonce,
            walletAddress: walletAddress,
            signedNonce: signedNonce
        }

        const response = await axios
            .post(`${process.env.REACT_APP_AUTH_BASE_URL}/api/v1/users/authenticate`, payload, {
                headers: {"device-id": "web-app",
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const currencyConversionRate = async (chain: string | null) => {
    
    let apiUrl = '';
    if (chain === null ) {
        console.error('No chain defined');
        return ;
    } else if(chain === 'POLYGON'){
        apiUrl = 'https://api.coinpaprika.com/v1/tickers/matic-polygon?quotes=USD'; 
    } else if(chain === 'ETHEREUM'){
        apiUrl = 'https://api.coinpaprika.com/v1/tickers/eth-ethereum?quotes=USD'; 
    }

    try {
        const response = await axios.get(apiUrl);
        return response?.data?.quotes?.USD?.price;
    } catch (error) {
        console.error('Failed to load API!', error)
        return {error}
    }
}

export const userProfile = async (userId: string | null, accessToken: string | null) => {
    if (!process.env.REACT_APP_PROFILE_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null) {
        console.error('No userId or accessToken specified for this api');
        return ;
    }
    try {
        const response = await axios
            .get(`${process.env.REACT_APP_PROFILE_BASE_URL}/api/v1/users/${userId}`, {
                headers: {"device-id": "web-app",
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const privateChannels = async (userId: string | null, accessToken: string | null) => {
    if (!process.env.REACT_APP_API_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null) {
        console.error('No userId or accessToken specified for this api');
        return ;
    }
    try {
        const response = await axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${userId}/communities?channelType=private`, {
                headers: {"device-id": "web-app",
                "Authorization": `Bearer ${accessToken}` }
            })
           
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const getTokens = async (userId: string | null, accessToken: string | null) => {
    if (!process.env.REACT_APP_PROFILE_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null) {
        console.error('No userId or accessToken specified for this api');
        return ;
    }
    try {
        const response = await axios
            .get(`${process.env.REACT_APP_PROFILE_BASE_URL}/api/v1/users/${userId}/erc20Tokens`, {
                headers: {"device-id": "web-app",
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const getNfts = async (userId: string | null, accessToken: string | null) => {
    if (!process.env.REACT_APP_PROFILE_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null) {
        console.error('No userId or accessToken specified for this api');
        return ;
    }
    try {
        const response = await axios
            .get(`${process.env.REACT_APP_PROFILE_BASE_URL}/api/v1/users/${userId}/nfts`, {
                headers: {"device-id": "web-app",
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const getTransactions = async (userId: string | null, accessToken: string | null) => {
    if (!process.env.REACT_APP_PROFILE_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null) {
        console.error('No userId or accessToken specified for this api');
        return ;
    }
    try {
        const response = await axios
            .get(`${process.env.REACT_APP_PROFILE_BASE_URL}/api/v1/users/${userId}/erc20TokenTransactions`, {
                headers: {"device-id": "web-app",
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const deleteUser = async (userId: string | null, accessToken: string | null) => {
    console.log("i'm hereee");
    if (!process.env.REACT_APP_PROFILE_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null) {
        console.error('No userId or accessToken specified for this api');
        return ;
    }
   try {
        const response = await axios
            .delete(`${process.env.REACT_APP_PROFILE_BASE_URL}/api/v1/users/${userId}`, {
                headers: {"device-id": "web-app",
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const usersProfile = async (userlist: [] | null, accessToken: string | null) => {
    if (!process.env.REACT_APP_PROFILE_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userlist === null || accessToken === null) {
        console.error('No accessToken specified for this api');
        return ;
    }
    try {
        const payload = {
            userIds: userlist
        };
        const response = await axios
            .post(`${process.env.REACT_APP_PROFILE_BASE_URL}/api/v1/users/lookup`, payload, {
                headers: {
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const sendMessage = async (userId: string | null, channelId: string | null, accessToken: string | null, pubnubToken: string | null, textMessage: string | null) => {
    if (!process.env.REACT_APP_API_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || channelId === null || textMessage === null || accessToken === null || pubnubToken === null) {
        console.error('No userId or accessToken or channelId or Message or pubnubToken specified for this api');
        return ;
    }
    try {
        const payload = {
            protobuf: textMessage    
        };
        const response = await axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${userId}/channels/${channelId}/messages`, payload, {
                headers: {
                "Authorization": `Bearer ${accessToken}`,
                "X-Publish-Token": `${pubnubToken}` 
                }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const getHistoryMessage = async (userId: string | null, channelId: string | null, accessToken: string | null, pubnubToken: string | null) => {
    if (!process.env.REACT_APP_API_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || channelId === null || accessToken === null || pubnubToken === null) {
        console.error('No userId or accessToken or channelId or pubnubToken specified for this api');
        return ;
    }
    try {
        const response = await axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${userId}/channels/${channelId}/messages?count=50&reverse=true&include=replies&include=reactions`, {
                headers: {
                "Authorization": `Bearer ${accessToken}`,
                "X-Publish-Token": `${pubnubToken}` 
                }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

export const getMessagingToken = async (userId: string | null, accessToken: string | null) => {
    if (!process.env.REACT_APP_AUTH_BASE_URL) {
        console.error('No base url specified for this api');
        return ;
    }
    if (userId === null || accessToken === null) {
        console.error('No userId or accessToken specified for this api');
        return ;
    }
    try {
        const response = await axios
            .get(`${process.env.REACT_APP_AUTH_BASE_URL}/api/v1/users/${userId}/messaging-token`, {
                headers: {
                "Authorization": `Bearer ${accessToken}` }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    } 
}

