import React, { useContext, useEffect, useState } from "react";
import LoaderAnimation from "@/components/Lottie/loader";
import Page from "@/layouts/Page";
import { AppContext } from "@/context/createContext";

import { isLogin } from "@/helper/common";
import { guestLogin } from "@/services/index";
import { useNavigate } from "react-router-dom";

import { CommunityFeedList } from "@/pages/index";
import routes from "@/config/routes";

const Home: React.FC = () => {

  let navigate = useNavigate();
  const  [loader, stopAnimation] = useState(false);
  const { state, walletConnector, checkWalletConnection, connect, isAuthenticate, disconnect, 
    addBellaUser, setError, resetError, pubnub, checkChatConnection, pubNubChatConnector } = useContext(AppContext);
  const [isLoggedIn, setIsLogin] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  let connectorCount = 0;

  useEffect(() => {

    if(!isLoggedIn){

      //check if I have a wallet connection
      if(walletConnector === null){

        checkWalletConnection('boolean').then(async (isConnected)=>{
            if(isConnected){
              await connect();
            // guest user
            } else {

              if (state?.bellaUser?.bellaUserId) {
                // guest user logged
                setIsLogin(isLogin(state?.bellaUser?.bellaUserId));
                stopAnimation(true);
              } else {
                // guest user not logged - call login services
                guestLogin()
                  .then(async (res: any) => {
                    if (res?.name === 'AxiosError'){
                      const error = {
                        visible: true,
                        text: res?.message,
                      }
                      setError(error);
                    }
                    else if(res?.data){
                      console.log(res.data)
                      addBellaUser(res.data); // true = guest
                      resetError(null);
                    }
                  })
                  .catch((error) => {
                    // ERROR
                    console.log(error);
                  });
              }

            }
        })
      } 

      //if I have a wallet connection - logged user
      if(walletConnector !== null) {

        (async function() {
          if(await isAuthenticate()){ 
            // authenticate user logged
            console.log("bellaUser from state: ", state?.bellaUser);
            setIsLogin(isLogin(state?.bellaUser?.bellaUserId));
            setIsUserAuthenticated(true);
            stopAnimation(true);
          } 
          else {
            disconnect(); //disconnect wallet connect session
          }
        })();
        
      } 
    }
  }, [addBellaUser, checkWalletConnection, connect, disconnect, isAuthenticate, isLoggedIn, resetError, setError, state?.bellaUser, state?.bellaUser?.bellaUserId, walletConnector]);

  useEffect(() => {

    // connect to chat service
    if(isLoggedIn){
      //check if I have a chat connection
      console.log("pubNubChatConnector", pubNubChatConnector);
      if(pubNubChatConnector === null && connectorCount === 0) {
        checkChatConnection('boolean').then( async (haveChat)=>{
          if(haveChat){
            await pubnub();
            connectorCount++;
          }
        })
      }
    }
  }, [checkChatConnection, connectorCount, isLoggedIn, pubNubChatConnector, pubnub]);

  useEffect(() => {
    document.body.className = "community-page";
    return () => {
        document.body.className = "";
    };
  }, []); 

  return (
    <>
    {!loader || !isLoggedIn ? (
      <section className="home-section h-100">
        <div className="container h-100 d-flex justify-content-center align-items-center">
          <LoaderAnimation height={100} width={100} />
        </div>
      </section>
      ) : (
        <>
        <Page>
          <section className="community-section h-100">
            <div className="container h-100">
              <div className="w-100 h-100">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {!isUserAuthenticated ? (
                    <div className="custom-mt-40">
                      <button className="custom-btn big-btn custom-mb-30" onClick={() => navigate(routes.wallet)}>
                        <p className="btn-text">Connect your wallet</p>
                      </button>
                    </div>
                  ) : (
                    <div className="separator custom-mt-30 custom-mb-30"></div>
                  )}
                  <div className="container-communities w-100">
                      <CommunityFeedList />
                    </div>
                </div>
              </div>
            </div>
          </section>
        </Page>
        </>
      )}
    </>
  );
};

export default Home;
