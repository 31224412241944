import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function isLogin(auth_userId: string | null): boolean {
    if ((auth_userId !== null)) {
        return true
    } else {
        return false
    }
}

export const useFindPath = () => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState<string>();
    useEffect(() => {
        setCurrentPath(location?.pathname);
    }, [location]);
    return currentPath;
};

export const clipString = (s: string) => {
    const clippedString = s.slice(0,6) + "..." + s.slice(s.length-4, s.length);
    return clippedString;
};
export const timestampToDate = (n: number) => {
    if(typeof n !== 'undefined' && n!==null ){
        const d = new Date(n);
        return d.getMonth()+1 + '/' + (d.getDate()) + '/' + d.getFullYear();
    }else {
        return "";
    }
  
};

const isString = function (obj: any) {    
    var toString = Object.prototype.toString;
    return toString.call(obj) === '[object String]';
}

export const timestampToTime = (n: any) => {
    
    if(!isString(n)) n = n.toString();
    const number = parseInt(n.slice(0, -4));
    if (isNaN(number) === false) {
        const date = new Date(number);
        /*let hours = date.getHours();
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const ampm = hours >= 12 ? 'pm' : 'am';
        const minutes = date.getMinutes();
        const min = minutes < 10 ? '0'+ minutes : minutes;
        var strTime = hours + ':' + min + ' ' + ampm;*/
        return date.toLocaleTimeString("en-US");

    }
    return null
};

export const encodeBase64 = (data: WithImplicitCoercion<ArrayBuffer | SharedArrayBuffer>) => {
    return Buffer.from(data).toString('base64');
}

export const decodeBase64 = (data: WithImplicitCoercion<string> | { [Symbol.toPrimitive](hint: "string"): string; }) => {
    return Buffer.from(data, 'base64').toString('ascii');
} 

export const checkSameString = (a: string, b:string) => {
    if (typeof a !== 'undefined' && typeof b !== 'undefined' && a!=="" && b!=="" && a!==null && b!==null ){
        if( a.toLowerCase() === b.toLowerCase()){
            return true;
        }else {
            return false;
        }
      }  else {
        return false;
    }
};

export const makeString = (length: number) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function getUnique(arr: { [x: string]: any; }[], comp: string) {
    const unique =  arr.map((e: { [x: string]: any; }) => e[comp])
    .map((e: any, i: any, final: string | any[]) => final.indexOf(e) === i && i)
    .filter((e: any) => arr[e]).map((e: any) => arr[e]);
    return unique;
}