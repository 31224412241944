import { getUnique } from '@/helper/common';
import { AppState } from '@/interface/index'
import { type } from 'os';

type AppAction =
    { type: "RESET_LIST_STATE" } |
    { type: "RESET_INITIAL_STATE", payload: AppState;  } |
    { type: "RESET_TOKEN_LIST_STATE" } |
    { payload: any; type: 'ADD_USER' } |
    { payload: any; type: 'ADD_MESSAGING' } |
    { payload: any; type: 'ADD_USER_PROFILE' } |
    { payload: any; type: 'ADD_PRIVATE_CHANNELS_LIST' } |
    { payload: any; type: 'ADD_TOKENS_LIST' } |
    { payload: any; type: 'ADD_TRANSACTIONS_LIST' } |
    { payload: any; type: 'ADD_NFT_LIST' } |
    { payload: any; type: 'ADD_COMMUNITY_FEED_LIST' } |
    { payload: any; type: 'SET_COMMUNITY_FEED' } |
    { payload: any; type: 'ADD_COMMUNITY_TOKEN_LIST' } |
    { payload: any; type: 'ADD_USER_CHANNEL_TOKEN_LIST' } |
    { payload: any; type: 'SET_COMMUNITY_TOKEN' } |
    { payload: any; type: 'ADD_WALLET_CONNECT_INFO' } |
    { payload: any; type: 'ADD_CHAT_DATA' } |
    { payload: any; type: 'ADD_PUBLIC_COMMUNITY_INFO' } |
    { payload: any; type: 'SET_ERROR' } |
    { payload: any; type: 'RESET_ERROR' } |
    { payload: any; type: 'ADD_MESSAGE_BY_CHANNEL' } |
    { payload: any; type: 'ADD_USER_PROFILE_CHAT' };

export const appReducer = (state: AppState, action: AppAction): AppState => {
    switch (action.type) {

        case "RESET_LIST_STATE":
            return {
                ...state,
                communityFeedList: [],
                selectedCommunityFeed: null,
                communityTokenList: [],
                userChannelTokenList: []
            };
        case "RESET_INITIAL_STATE":
            return {
                ...action.payload
            }

        case "RESET_TOKEN_LIST_STATE":
            return {
                ...state,
                communityTokenList: [],
                userChannelTokenList: []
            }

        case "ADD_USER":
            return {
                ...state,
                bellaUser: action.payload
            };

        case "ADD_MESSAGING":

            let bellaUserList = state?.bellaUser;
            bellaUserList.messaging = action.payload
            console.log("ADD_MESSAGING - bellaUserList", bellaUserList)

            return {
                ...state,
                bellaUser: bellaUserList
            };

        case "ADD_COMMUNITY_FEED_LIST":
            return {
                ...state,
                communityFeedList: action.payload
            };
        case "SET_COMMUNITY_FEED":
            return {
                ...state,
                selectedCommunityFeed: action.payload
            };
        case "ADD_COMMUNITY_TOKEN_LIST":
            return {
                ...state,
                communityTokenList: action.payload
            };
        case "ADD_USER_CHANNEL_TOKEN_LIST":
            return {
                ...state,
                userChannelTokenList: action.payload
            };
        case "SET_COMMUNITY_TOKEN":
            return {
                ...state,
                selectedToken: action.payload
            };
        case "ADD_WALLET_CONNECT_INFO":
            return {
                ...state,
                walletConnectInfo: action.payload
            };
        case "ADD_CHAT_DATA":
            return {
                ...state,
                walletConnectInfo: action.payload
            };
        case "ADD_PUBLIC_COMMUNITY_INFO":
            return {
                ...state,
                publicCommunity: action.payload
            };
        case "ADD_USER_PROFILE":
            return {
                ...state,
                userProfile: action.payload
            };
        case "ADD_PRIVATE_CHANNELS_LIST":
            return {
                ...state,
                privateChannelsList: action.payload
            };
        case "ADD_TOKENS_LIST":
            return {
                ...state,
                tokensList: action.payload
            };
        case "ADD_TRANSACTIONS_LIST":
            return {
                ...state,
                transactionsList: action.payload
            };
        case "ADD_NFT_LIST":
            return {
                ...state,
                nftList: action.payload
            };
        case "SET_ERROR":
            return {
                ...state,
                error: action.payload
            };
        case "RESET_ERROR":
            return {
                ...state,
                error: action.payload
            };    
        case "ADD_MESSAGE_BY_CHANNEL":

            let messageList: any = {};
            if(state?.messageList !== null){
                messageList = state.messageList
            }

            const msg = action.payload[0];
            if(state?.userProfileList){
                const infoUser = state?.userProfileList[msg?.userId];
                if(infoUser) {
                    msg.userName = state?.userProfileList[msg?.userId].alias;
                    msg.userIcon = state?.userProfileList[msg?.userId].avatarUrl;
                }
            }

            if(Boolean(messageList[msg?.channel])){
               if(action.payload[1] === "instant"){
                    console.log('instant');
                    messageList[msg?.channel].splice(0, 0, msg);
                } else 
                    messageList[msg?.channel].push(msg)
                 /*messageList[msg?.channel].push(msg)    */
            } else {
                messageList[msg?.channel] = []; 
                messageList[msg?.channel].push(msg)
            }

            //console.log("messageList", messageList[msg?.channel])
            messageList[msg?.channel] = getUnique(messageList[msg?.channel],'timestamp')
            //console.log("messageList", messageList[msg?.channel])

            return {
                ...state,
                messageList
            };

        case "ADD_USER_PROFILE_CHAT":
            let userProfileList: any = [];
            if(state?.userProfileList !== null)
                userProfileList = state.userProfileList

            const user = action.payload;
            if(typeof userProfileList[user?.userId] === "undefined"){
                userProfileList[user?.userId] = user
            } 
            //console.log("userList", userProfileList)
            return {
                ...state,
                userProfileList
            };


        default:
            return state;
    }
}