import React, { useContext, useEffect, useState } from "react";
import Page from "@/layouts/Page";
import LoaderAnimation from "@/components/Lottie/loader";
import { Scrollbars } from 'react-custom-scrollbars';
import { AppContext } from "@/context/createContext";
import { communityItem } from "@/services/index";
import { arrowDown, angleSx, angleDx, bellaToken, hands, customers } from "@/config/svg";
import { useNavigate } from 'react-router-dom';
import routes from "@/config/routes";
import LazyLoad from "react-lazyload";
import FadeIn from "react-fade-in";


const CommunitySelectedFeed: React.FC = () => {

  let navigate = useNavigate();
  const [loader, stopAnimation] = useState(false);
  const { state, walletConnector, checkWalletConnection, connect, isAuthenticate, disconnect,
    selectCommunityFeed, addCommunityTokenList, addUserChannelTokenList, addPublicCommunityInfo,
    resetError, setError } = useContext(AppContext);
  const [selectedCommunityFeed, setSelectedCommunityFeed] = useState<any>(null);
  const [showDescription, isDescriptionVisible] = useState(true);
  const [selectedCommunityTokenList, setSelectedCommunityTokenList] = useState<any>(null);
  const [userChannelTokenList, setUserChannelTokenList] = useState<any>(null);
  const [selectedCommunityPublic, setSelectedCommunityPublic] = useState<any>(null);

  const [userChannelToken, setUserChannelToken] = useState(false);
  const [hasAllTokens, setHasAllTokens] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [selectedChat, setSelectedChat] = useState<any>(null);



  useEffect(() => {
    document.body.className = "communitySelectedFeed-page";
    return () => {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {

    // end condition
    if (selectedCommunityFeed === null) {

      // check if I have the info saved on context
      if (state?.selectedCommunityFeed) {
        setSelectedCommunityFeed(state?.selectedCommunityFeed);
      } else {
        // check if I have the info saved on session
        const getSelectedCommunityFeed = localStorage.getItem("selectedCommunityFeed");
        if (getSelectedCommunityFeed && getSelectedCommunityFeed !== "") {
          selectCommunityFeed(JSON.parse(getSelectedCommunityFeed), false); // save info again on state
        }
        else{
          console.log("selectedCommunityFeed not found");
          navigate(routes.root)
        }
      }
    }

  }, [selectCommunityFeed, selectedCommunityFeed, state?.selectedCommunityFeed]);

  useEffect(() => {

    //check if I have a wallet connection
    if (walletConnector === null) {

      checkWalletConnection('boolean').then(async (isConnected) => {
        if (isConnected) {
          await connect();
        } else {
          // guest user - silent is golden
        }
      })
    }

    //if I have a wallet connection - logged user
    if (walletConnector !== null) {

      (async function () {
        if (await isAuthenticate()) {
          console.log('selectedCommunityPublic',{selectedCommunityPublic},{typeofselectedCommunityPublic :typeof selectedCommunityPublic});
          
          // end condition
          if (selectedCommunityPublic === null || typeof selectedCommunityPublic === "undefined") {

            // check if I have the info saved on context
            if (state?.userChannelTokenList?.length > 0) {
              console.log("state?.userChannelTokenList",state?.userChannelTokenList);
              if (userChannelTokenList === null || typeof userChannelTokenList === "undefined") {
                const communityActive = (state?.userChannelTokenList).filter(function (community: { type: string; }) {
                  return (community.type === "private_channel");
                });
                console.log(communityActive)
                setUserChannelTokenList(communityActive);
              }
              setUserChannelToken(true);

              if (selectedCommunityPublic === null || (selectedCommunityPublic && selectedCommunityPublic?.length === 0)) {
                const communityPublic = (state?.userChannelTokenList).filter(function (community: { type: string; }) {
                  return (community.type === "main_community_channel");
                });
                addPublicCommunityInfo(communityPublic[0], true);
                setSelectedCommunityPublic(communityPublic[0]);
              }
              if(
                    (selectedCommunityPublic !== null && typeof selectedCommunityPublic !== "undefined" && selectedCommunityPublic?.length > 0) || 
                    (userChannelTokenList !== null && typeof userChannelTokenList !== "undefined" && userChannelTokenList?.length > 0)
              ){
                
                stopAnimation(true);
              }

            } else {

              // NOTE: not ok bring them from session: if customer update the list, I don't see it
              // get again the infos (first flow or refresh page)
              console.log("ELSE addUserChannelTokenList");
              if (state?.selectedCommunityFeed && state?.selectedCommunityFeed?.communityId !== "") {

                const bellaUserId = localStorage.getItem("bellaUserId");
                const accessToken = localStorage.getItem("accessToken");

                if (bellaUserId && bellaUserId !== "" && accessToken && accessToken !== "") {
                  communityItem(bellaUserId, accessToken, state?.selectedCommunityFeed?.communityId, true) //isMember
                    .then(async (res: any) => {
                      if(res?.response?.status === 401){
                          disconnect();
                          window.location.reload();
                      }
                      else if (res?.name === 'AxiosError'){
                          const error = {
                              visible: true,
                              text: res?.message,
                          }
                          setError(error);
                      }
                      else if (res?.data?.results) {
                        addUserChannelTokenList(res?.data?.results, true);
                        console.log("addUserChannelTokenList",res?.data?.results);
                        resetError(null);
                      }
                    })
                    .catch((error) => {
                      // ERROR
                      console.log(error);
                    });

                } else
                  console.log("ERROR DATA NOT FOUND");
              }
            }
            setIsUserAuthenticated(true);    
          }
          if(selectedCommunityPublic && selectedCommunityPublic?.length === 0){
            stopAnimation(true);
          }
        }
        else {
          disconnect(); //disconnect wallet connect session
        }
      })();
    }
  }, [addPublicCommunityInfo, addUserChannelTokenList, checkWalletConnection, connect, disconnect, isAuthenticate, navigate, resetError, selectedCommunityPublic, setError, state, userChannelTokenList, walletConnector]);

  useEffect(() => {

    // end condition
    if (selectedCommunityTokenList === null) {

      // check if I have the info saved on context
      if (state?.communityTokenList?.length > 0) {
        console.log('communityTokenList exist');
        

        if (selectedCommunityTokenList === null) {
          const communityActive = (state?.communityTokenList).filter(function (community: any) {
            return (community.type === "private_channel");
          });
          setSelectedCommunityTokenList(communityActive);
        }

        if (selectedCommunityPublic === null || (selectedCommunityPublic && selectedCommunityPublic?.length === 0)) {
          const communityPublic = (state?.communityTokenList).filter(function (community: { type: string; }) {
            return (community.type === "main_community_channel");
          });
          addPublicCommunityInfo(communityPublic[0], true);
          setSelectedCommunityPublic(communityPublic[0]);
        }
        stopAnimation(true);

      } else {
        console.log('communityTokenList not exist');
        // NOTE: not ok bring them from session: if customer update the list, I don't see it
        // get again the infos (first flow or refresh page)
        if (state?.selectedCommunityFeed && state?.selectedCommunityFeed?.communityId !== "") {

          const bellaUserId = localStorage.getItem("bellaUserId");
          const accessToken = localStorage.getItem("accessToken");

          if (bellaUserId && bellaUserId !== "" && accessToken && accessToken !== "") {
            communityItem(bellaUserId, accessToken, state?.selectedCommunityFeed?.communityId, false) // !isMember
              .then(async (res: any) => {
                if(res?.response?.status === 401){
                  disconnect();
                  window.location.reload();
                }
                else if (res?.name === 'AxiosError'){
                  const error = {
                      visible: true,
                      text: res?.message,
                  }
                  setError(error);
                }
                else {
                  if (res?.data) {
                    const result = res?.data?.results;
                    if (result?.length > 0) {
                      addCommunityTokenList(res?.data?.results, true);
                      setHasAllTokens(false);
                    }
                    else {
                      setSelectedCommunityTokenList([]);
                      addCommunityTokenList([], true);
                     // setHasAllTokens(true);
                      //stopAnimation(true);
                    }
                  }
                }
              })
              .catch((error) => {
                // ERROR
                console.log(error);
              });
            
          } 
        }
      }
    }

    if(selectedCommunityTokenList && selectedCommunityTokenList?.length === 0){
      stopAnimation(true);
    }

  }, [addCommunityTokenList, addPublicCommunityInfo, disconnect, navigate, selectedCommunityPublic, selectedCommunityTokenList, setError, state]);

  const handleSelectToken = async (channelId: string) => {
    if (channelId !== null && channelId !== "") {
      //selectCommunityToken(channelId);
      navigate("/tokens/" + channelId);
    }
  };

  const handleSelectedChat = async (channelInfo: any) => {
    if (channelInfo !== null && channelInfo !== "") {
      addPublicCommunityInfo(channelInfo, true);
      setSelectedChat(channelInfo);

      //selectCommunityToken(channelId);
      navigate(routes.communityChat);
    }
  };

  return (
    <>
      {!loader || selectedCommunityFeed === null ? (
        <section className="community-section h-100">
          <div className="container h-100 d-flex justify-content-center align-items-center">
            <LoaderAnimation height={100} width={100} />
          </div>
        </section>
      ) : (
        <>
          <Page>
            <section className="community-section h-100">
              <div className="container h-100">
                <div className="row h-100">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="w-100 item-block position-relative">

                      <div className="public-chat-container position-absolute cursor-pointer">
                        <button className="custom-btn square-bnt " onClick={() => handleSelectedChat(selectedCommunityPublic)}>
                          <img className="customer" src={customers} alt="icon" />
                        </button>
                      </div>

                      <div className="item-title custom-mt-20">
                        <div className="d-flex justify-content-center align-items-center cursor-pointer"
                          onClick={() => isDescriptionVisible(!showDescription)}>
                          <h1
                            className="medium-title-page custom-mr-10">
                            {selectedCommunityFeed?.communityName}
                          </h1>
                          <img className={!showDescription ? "arrow-up" : "arrow-down"} src={arrowDown} alt="arrow down" />
                        </div>
                        {showDescription ? <div className="description-title text-center custom-mt-20 position-relative">
                          <p className="regular-text">{selectedCommunityFeed?.description}</p>
                          <img className="position-absolute angle-sx" src={angleSx} alt="angle-sx icon" />
                          <img className="position-absolute angle-dx" src={angleDx} alt="angle-dx icon" />
                        </div> : <></>}
                      </div>

                      {userChannelToken && userChannelTokenList && userChannelTokenList?.length > 0 ? (
                        <div className="custom-mt-55">
                          <h2 className="rainbow-title-page custom-mb-26 text-center">YOUR CHANNEL TOKENS</h2>
                          {userChannelTokenList?.length === 0 ? (
                            <div className="text-center custom-mr-5 custom-pl-0 custom-pr-0">
                              <p className="regular-text">No channel</p>
                            </div>
                          ) : (
                            <LazyLoad height={200} once >
                              <FadeIn transitionDuration={800}>
                              <div className="user-tokens">
                                <Scrollbars className="custom-scrollbar" >
                                <div>
                                  <div className={userChannelTokenList?.length > 0 ? "container-tokens custom-ml-20 row items" + userChannelTokenList?.length : "container-product custom-ml-20 row items0"} >
                                    {userChannelTokenList?.length > 0 &&
                                      userChannelTokenList.map((item: any, index: number) => (
                                        <div key={index.toString()}
                                          className="block custom-mr-5 d-flex justify-content-center align-items-center custom-pl-0 custom-pr-0"
                                        >
                                          <div className={item?.numberOfUnreadMessages > 0 ? "romboblock has-msg position-relative" : "romboblock position-relative"}
                                            style={{ backgroundImage: `url(${bellaToken})` }} onClick={() => handleSelectedChat(item)}>
                                            <img className="position-absolute" src={item?.iconUrl} alt="icon" />
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                                </Scrollbars>
                              </div>
                              </FadeIn>
                            </LazyLoad>
                          )}
                        </div>
                      ) : <></>
                      }

                      {!hasAllTokens && selectedCommunityTokenList && selectedCommunityTokenList?.length > 0 ? (
                        <div className="custom-mt-55">
                          <h2 className="rainbow-title-page custom-mb-26 text-center">AVAILABLE TO JOIN</h2>
                          <LazyLoad height={200} once >
                            <FadeIn transitionDuration={800}>
                              <div className={selectedCommunityTokenList?.length > 0 ? "flex-nowrap container-product custom-ml-20 row items" + selectedCommunityTokenList?.length : "container-product custom-ml-20 row items0"} >
                                {selectedCommunityTokenList?.length > 0 &&
                                  selectedCommunityTokenList.slice(0, 7).map((item: any, index: number) => (
                                    <div key={index.toString()}
                                      className="block custom-mr-13 d-flex justify-content-center align-items-center product-item custom-pl-0 custom-pr-0 flex-shrink-0"
                                      style={{ backgroundImage: `url(${item?.profileImageUrl})` }}>
                                      {(item?.tokensAvailable > 0) ? (
                                        <div className="inner" onClick={() => handleSelectToken(item?.channelId)}>
                                          <div className="romboblock position-relative" style={{ backgroundImage: `url(${bellaToken})` }}>
                                            <img className="position-absolute" src={item?.iconUrl} alt="icon" />
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="inner">
                                          <div className="romboblock position-relative" style={{ backgroundImage: `url(${bellaToken})` }}>
                                            <img className="position-absolute" src={item?.iconUrl} alt="icon" />
                                          </div>
                                          <div className="soldout_overlay"><div className="soldout_overlay_bg"></div>
                                            <span className="medium-text-small text custom-pb-30 text-center">SOLD OUT</span></div>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            </FadeIn>
                          </LazyLoad>
                          <div className="text-center">
                            <button className="custom-btn super-small-bnt custom-mt-30" onClick={() => navigate(routes.communityTokensList)}>
                              <p className="btn-text">View All</p>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="custom-mt-55">
                          <h2 className="rainbow-title-page custom-mb-26 text-center">YAY!</h2>
                          <div className="text-center hands-wrapper d-flex flex-column justify-content-end" style={{ backgroundImage: `url(${hands})` }}>
                            <p className="medium-text-small custom-pr-50 custom-pl-50">You have all the available access NFTs for this Community.</p>
                          </div>
                        </div>
                      )}

                      {!isUserAuthenticated ? (
                        <div className="text-center custom-mt-82">
                          <button className="custom-btn small-bnt" onClick={() => navigate(routes.wallet)}>
                            <p className="btn-text">Connect your wallet</p>
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Page>
        </>
      )}
    </>
  );
};

export default CommunitySelectedFeed;