import React, { useEffect } from "react";
import Page from "@/layouts/Page";
import routes from "@/config/routes";
import { flowerXl } from "@/config/svg";
import { useNavigate } from "react-router-dom";


const Success: React.FC = () => {
    let navigate = useNavigate();
 /*   useEffect(() => {
        setTimeout(() => {
            navigate(routes.root);
          }, 3000);
    }, []);*/
    useEffect(() => {
        document.body.className = "success-page";
        return () => {
            document.body.className = "";
        };
    }, []);

    const handleGoToHome = async () => {
        localStorage.setItem("waiting-response","true");
        navigate(routes.root)
    };
                
    return (
        <>
            <Page>
            <section className=" h-100 flex-column d-flex flex-grow-1 justify-content-end">
                   
                    <div className="flower-bg" style={{ backgroundImage: `url(${flowerXl})` }}></div>
                    <div className="container flex-column d-flex ">
                        <div className="row h-100">
                            <div className=" d-flex flex-column justify-content-between align-items-center custom-mb-50 text-center custom-pr-45 custom-pl-45">
                                <div className="d-flex flex-column">
                                <h1 className="xl-title custom-mb-10 rainbow-outline">GREAT!</h1>
                                <h2 className="second-title-page-large custom-mb-30">Your NFT purchase is pending network confirmation.</h2>
                                <p className="grey regular-text-small">This may take a few minutes. Your profile will be updated to show the new channel in the "Private channels you joined" section once it's ready.</p>
                                </div>
                                <button className="custom-btn super-small-bnt custom-mt-40" onClick={() => handleGoToHome() }>
                                    <p className="btn-text ">Done</p>
                                </button>

                            </div>

                        </div>
                    </div>
                   
                </section>
            </Page>
        </>
    )
};

export default Success;


