import React, { useContext, useEffect, useState } from "react";
import Page from "@/layouts/Page";
import LoaderAnimation from "@/components/Lottie/loader";

import { AppContext } from "@/context/createContext";
import { useNavigate, useParams } from "react-router-dom";
import { bellaToken, open_clip_sx, open_clip_dx, plus, customers, polygon, ethereum } from "@/config/svg";
import routes from "@/config/routes";


const CommunityTokenDetails: React.FC = () => {

  console.log('asd');
  const { state, walletConnector, checkWalletConnection, connect, isAuthenticate, selectCommunityFeed, disconnect } = useContext(AppContext);
  const params = useParams();
  const [selectedCommunityToken, setSelectedCommunityToken] = useState<any>(null);
  const [loader, stopAnimation] = useState(false);
  let navigate = useNavigate();
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  useEffect(() => {
    document.body.className = "communityTokenDetails-page";
    return () => {
      document.body.className = "";
    };

  }, []);

  function getTokenFromList(list: any[], channelId: string | undefined) {
    return list.find((element) => {
      return element.channelId === channelId;
    })
  }

  useEffect(() => {
    //check if I have a wallet connection
    if (walletConnector === null) {

      checkWalletConnection('boolean').then(async (isConnected) => {
        if (isConnected) {
          await connect();
        } else {
          // guest user - silent is golden
        }
      })
    }

    //if I have a wallet connection - logged user
    if (walletConnector !== null) {

      (async function () {
        if (await isAuthenticate()) {
          setIsUserAuthenticated(true);
        }
        else {
          disconnect(); //disconnect wallet connect session
        }
      })();

    }

  });

  useEffect(() => {

    if (selectedCommunityToken === null) {

      if (state?.communityTokenList && state?.communityTokenList?.length > 0) {
        // setto prodotto
        const t = getTokenFromList(state?.communityTokenList, params?.id);
        setSelectedCommunityToken(t);
        stopAnimation(true);
      } else {
        const getCommunityTokenList = localStorage.getItem("communityTokenList");
        if (getCommunityTokenList && getCommunityTokenList !== "") {
          //setto prodotto
          const t = getTokenFromList(JSON.parse(getCommunityTokenList), params?.id);
          selectCommunityFeed(t, false); // save info again on state
        }
      }
    }
   

  }, [params?.id, selectCommunityFeed, selectedCommunityToken, state?.communityTokenList]);

  const currencyImage = (currency: string) => {
    return (currency === "POLYGON") ? polygon : ethereum;
  };

  const handleSelectToken = async (channelId: string) => {
    if (channelId !== null && channelId !== "") {
      //selectCommunityToken(channelId);
      navigate("/checkout/" + channelId);

    }
  };

  return (
    <>
      {!loader || selectedCommunityToken == null ? (
        <section className="community-section h-100">
          <div className="container h-100 d-flex justify-content-center align-items-center">
            <LoaderAnimation height={100} width={100} />
          </div>
        </section>
      ) : (
        <>
          <Page>
            <section className="community-section h-100">
              <div className="container h-100">
                <div className="row h-100">
                  <div className="item-block">
                    <div className="custom-mt-17 text-center">
                      <h2 className="rainbow-title-page custom-mb-35">{selectedCommunityToken?.channelName}</h2>
                      <div className="container-list custom-pl-30 custom-pr-30">
                        {selectedCommunityToken && (

                          <div className="row flex-column d-flex align-items-center justify-content-center">

                
                      <div className="token-counter-bar position-relative custom-mb-10">
                      <div className="h-100 background-block position-absolute " style={{ width: `calc((${selectedCommunityToken?.tokensAvailable / selectedCommunityToken?.tokensTotal}* 100%) - 2em)` }} >
                               </div>

                      </div>
                      <p className="grey regular-text-small"><span className="white">Available NFTs: {selectedCommunityToken?.tokensAvailable}</span> / {selectedCommunityToken?.tokensTotal}</p>

                            

                            <div className="custom-mt-40 custom-mb-40 romboblock position-relative" style={{ backgroundImage: `url(${bellaToken})` }}>
                              <img className="position-absolute" src={selectedCommunityToken?.iconUrl} alt="icon" />
                            </div>

                            <div className="container-description position-relative">
                              <div className="image-clip position-absolute icon-clip-open" style={{ backgroundImage: `url(${open_clip_sx})` }}></div>
                              <div className="custom-mt-15 custom-mb-15 custom-pl-26 custom-pr-26">{selectedCommunityToken?.description}</div>
                              <div className="image-clip position-absolute icon-clip-close" style={{ backgroundImage: `url(${open_clip_dx})` }}></div>
                            </div>

                            <div className="custom-mt-10 custom-mnb-10 plus-conyainer">
                              <img src={plus} alt="plus-icon" />
                            </div>

                            <div className="container-description position-relative">
                              <div className="image-clip position-absolute icon-clip-open" style={{ backgroundImage: `url(${open_clip_sx})` }}></div>
                              <div className="custom-mt-15 custom-mb-15 custom-pl-26 custom-pr-26">
                                <p>Gain access to the {selectedCommunityToken?.channelName} private chat.</p>
                                <div className="custom-mt-26 d-flex align-items-center justify-content-center">
                                  <p className="light-text text-left">
                                    {selectedCommunityToken?.numberOfParticipants}
                                    <img className="custom-ml-10" src={customers} alt="icon" />
                                  </p>
                                </div>
                              </div>
                              <div className="image-clip position-absolute icon-clip-close" style={{ backgroundImage: `url(${open_clip_dx})` }}></div>
                            </div>

                            <div className="text-center custom-mt-40 custom-mb-30">
                              {!isUserAuthenticated ? (
                                <button className="custom-btn small-bnt" onClick={() => navigate(routes.wallet)}>
                                  <p className="btn-text">Connect your wallet</p>
                                </button>
                              ) : (
                                <button className="custom-btn small-bnt" onClick={() => handleSelectToken(selectedCommunityToken?.channelId)}>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <p className="btn-text">
                                      <img
                                        className={
                                          selectedCommunityToken?.tokenPrice
                                            ? "custom-mr-10 icon-currency"
                                            : "hide"
                                        }
                                        src={currencyImage(selectedCommunityToken?.chain)} alt="icon" />
                                      {selectedCommunityToken?.tokenPrice}
                                    </p>
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Page>
        </>
      )}
    </>
  )
};

export default CommunityTokenDetails;
