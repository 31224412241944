import { BrowserRouter } from "react-router-dom";
import AppRouter from "./AppRouter";
import { AppContextProvider } from "./context/provider/AppContextProvider";
import Error from "@/components/Lottie/error";
import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <AppRouter />
        <Error />
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;
