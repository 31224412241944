import { AppContext } from "@/context/createContext";
import { ProviderProps } from "@/interface/type";
import { AppState, Message } from "@/interface/index";
import { useEffect, useReducer, useState } from "react";
import { appReducer } from "../reducer/appReducer";
import { bridgeUrl, getMessagingToken, usersProfile } from "@/services/index";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import Pubnub from "pubnub";
import routes from "@/config/routes";
import { useNavigate } from "react-router-dom";

const INITIAL_STATE: AppState = {
    bellaUser: {
        bellaUserId: null,
        accessToken: null,
        refreshToken: null,
        messaging: null
    },
    communityFeedList: [],
    selectedCommunityFeed: null,
    communityTokenList: [],
    userChannelTokenList: [],
    selectedToken: null,
    walletConnectInfo: null,
    error: null,
    publicCommunity: null,
    messageList: null,
    userProfileList: null,
    userProfile: null,
    privateChannelsList: null,
    tokensList: null,
    transactionsList: null,
    nftList: null
};

const chains:any = {'MAINNET':{'ETHEREUM':1,'POLYGON':137},'GOERLI':{'ETHEREUM':5},'MUMBAI':{'POLYGON':80001}};

export const AppContextProvider = ({ children }: ProviderProps) => {

    let navigate = useNavigate();
    const [state, dispatch] = useReducer(appReducer, INITIAL_STATE);

    // walletconnect functions stars
    const [connector, setConnector] = useState<any>(null);
    const [initSession, setInitSession] = useState<boolean>(false);
    const [accountsIDs, setAccountsIDs] = useState<any>(false);

    useEffect(() => {
        if (initSession)
            initTriggers();
    }, [initSession]);

    const checkWalletConnection = async (returnType: 'object' | 'boolean' = 'object'): Promise<WalletConnect | boolean> => {

        const bridUrl = await bridgeUrl();
        const bridge = bridUrl?.url ?? '';

        const connectorP = new WalletConnect({
            bridge, 
            qrcodeModal: QRCodeModal, qrcodeModalOptions: {
                desktopLinks: ['ledger', 'tokenary', "metamask", 'wallet', "trust"],
                mobileLinks: ["rainbow", "metamask", "argent", "trust", "imtoken", "pillar",],
            },/**/
        });

        if (returnType === 'object')
            return connectorP;
        else if (returnType === 'boolean')
            return (connectorP !== null && connectorP.connected);

        return false;
    }

    const connect = async (): Promise<void> => {
        console.log("fn -> connect");
        const connectorP = await checkWalletConnection() as WalletConnect;
        // console.log({connectorP});

        if (connectorP !== null && !connectorP.connected){
            await connectorP.createSession();
            console.log("connectorP create session");
        }
            
        else if (connectorP !== null && connectorP.connected) {
            
            const walletConnectInfo = {
                accounts: connectorP.accounts,
                chainId: connectorP.chainId,
                peerId: connectorP.peerId,
                peerMeta: connectorP.peerMeta
            }
            console.log({walletConnectInfo: walletConnectInfo});
            addWalletConnectInfo(walletConnectInfo, false)
        }
        setConnector(connectorP);
        setInitSession(true)
    };

    const initTriggers = async () => {
        console.log("fn -> initTriggers");

        // Subscribe to connection events
        connector.on("connect", async (error: any, payload: any) => {
            console.log(`connector.on("connect")`);

            if (error) {
                throw error;
            }

            const walletConnectInfo = {
                accounts: payload?.params[0]?.accounts,
                chainId: payload?.params[0]?.chainId,
                peerId: payload?.params[0]?.peerId,
                peerMeta: payload?.params[0]?.peerMeta
            }

            addWalletConnectInfo(walletConnectInfo, true);

            // Get provided accounts and chainId
            const { accounts, chainId } = payload?.params[0];
            setAccountsIDs(accounts);
            console.log({ accounts, chainId });
        });

        connector.on("session_request", async (error: any, payload: any) => {
            console.log(`connector.on("session_request")`);
            if (error) {
                throw error;
            }
            console.log({ session_request: payload });
        });

        connector.on("session_update", async (error: any, payload: any) => {
            console.log(`connector.on("session_update")`,{payload});
            if (error) {
                throw error;
            }
            const walletConnectInfo = {
                accounts: connector.accounts,
                chainId: connector.chainId,
                peerId: connector.peerId,
                peerMeta: connector.peerMeta
            }
               console.log({walletConnectInfo: walletConnectInfo});
            addWalletConnectInfo(walletConnectInfo, false)
            // console.log({payload});
            
        });

        connector.on("disconnect", async (error: any, payload: any) => {
            console.log(`connector.on("disconnect")`);
            if (error) {
                throw error;
            }
            onDisconnect();
        });
    }

    const onDisconnect = () => {
        if(connector.connected)
        connector.killSession();
        setInitSession(false);
        setConnector(null);
        addWalletConnectInfo(null, false);
        setAccountsIDs(false);
        localStorage.removeItem("walletConnectInfo");
    };

    const disconnect = async (): Promise<void> => {

        console.log("connector.connected", connector.connected);
        if (connector.connected) {
            console.log(`disconnect clicked`);
            connector.killSession();
        } else {
            onDisconnect();
        }
    };

    const signMessage = async (payload: any): Promise<string> => {
        let signature = "";
        if (connector.connected) {
            console.log(`authentication`);
            signature = await connector.signPersonalMessage(payload);
        }
        return signature;
    };

    const isAuthenticate = async (): Promise<boolean> => {
        let signed = false;

        if (connector && connector.connected) {
            if (state?.bellaUser?.bellaUserId && state?.bellaUser?.accessToken
                && state?.bellaUser?.refreshToken && state?.bellaUser?.messaging)
                signed = true;
            else {
                const getBellaUserId = localStorage.getItem("bellaUserId");
                const getAccessToken = localStorage.getItem("accessToken");
                const getRefreshToken = localStorage.getItem("refreshToken");
                const getMessaging = localStorage.getItem("messaging");
                if (getBellaUserId && getBellaUserId !== "" &&
                    getAccessToken && getAccessToken !== "" &&
                    getRefreshToken && getRefreshToken !== "" &&
                    getMessaging && getMessaging !== "") {

                    const bellaUser = {
                        bellaUserId: getBellaUserId,
                        accessToken: getAccessToken,
                        refreshToken: getRefreshToken,
                        messaging: JSON.parse(getMessaging)
                    };
                    addBellaUser(bellaUser); // to have it on state too
                    signed = true;
                }
            }
        }

        console.log("authentication done: " + signed);
        return signed;
    };

    const checkChainAndNetwork = (chain:string, network:string):boolean =>{ 
        const chainC = chain.toUpperCase();
        const networkC = network.toUpperCase();
        if(typeof chains[networkC][chainC] === 'undefined')
        return false;

        if(connector !== null && connector.connected){
            return parseInt(connector.chainId) === parseInt(chains[networkC][chainC]);
        }

        return false;

    }

    /// walletconnect function ends

    // start chat logic
    const [pubnubChat, setPubnubChat] = useState<any>(null);

    const checkChatConnection = async (returnType: 'object' | 'boolean' = 'object'): Promise<Pubnub | boolean> => {

        let publisherKey = ""; let subscriberKey = ""; let userId = "";
        if (state?.bellaUser?.messaging && state?.bellaUser?.bellaUserId) {
            publisherKey = state?.bellaUser?.messaging?.publisherKey;
            subscriberKey = state?.bellaUser?.messaging?.subscriberKey;
            userId = state?.bellaUser?.bellaUserId;
        } else {
            const getMessaging = localStorage.getItem("messaging");
            const getBellaUserId = localStorage.getItem("bellaUserId");
            if (getMessaging && getMessaging !== "" && getBellaUserId && getBellaUserId !== "") {
                publisherKey = JSON.parse(getMessaging)?.publisherKey;
                subscriberKey = JSON.parse(getMessaging)?.subscriberKey;
                userId = getBellaUserId;
            }

            const getBellaUser = localStorage.getItem("bellaUser");
            if (getBellaUser && getBellaUser !== "") {
                addBellaUser(getBellaUser)
            }
        }

        if (publisherKey && subscriberKey && userId) {

            console.log("INSIDE NEW");
            const chatPubnub = new Pubnub({
                publishKey: publisherKey,
                subscribeKey: subscriberKey,
                userId: userId,
                ssl: true,
                restore: true
            });

            if (returnType === 'object')
                return chatPubnub;
            else if (returnType === 'boolean')
                return (chatPubnub !== null);
        }

        return false;
    }

    const messagePB = require('@lookio/bella-models-registry/src/message_pb');
    const contentPB = require('@lookio/bella-models-registry/src/content_pb');
    const textMessageDetailsPB = require('@lookio/bella-models-registry/src/text_message_details_pb');
    const contentTypePB = require('@lookio/bella-models-registry/src/content_type_pb');

    const listener = {

        status: (statusEvent: { category: string; }) => {
            console.log("statusEvent", statusEvent);
            if (statusEvent.category === "PNConnectedCategory") {
                console.log("Connected");
            }
            else if (statusEvent.category === "PNAccessDeniedCategory") {
                console.log("token expired");
                // set new token
                getNewToken(pubnubChat);
            }
        },

        messageAction: (messageAction: any) => {
            console.log("MESSAGE ACTION EVENT")
            console.log(messageAction)
        },

        message: (messageEvent: any) => {
            console.log("MESSAGE EVENT")
            console.log(messageEvent)
            if(messageEvent){

                try{
                    
                    const test = new Uint8Array(Buffer.from(messageEvent?.message.protobuf, 'base64'));
                    const messageFromProto = new messagePB.Message(test);
                    const messageFromProto2 = messagePB.Message.deserializeBinary(test);
                    const messageFromProtoObj = messageFromProto2.toObject();

                    // need to refresh token - set new token
                    if (messageFromProto2?.getContent()?.getContentType() === contentTypePB?.ContentType?.REFRESH_TOKEN) {
                        getNewToken(pubnubChat);
                    }
                    
                    if (messageFromProtoObj.messageType === messagePB.Message.MessageType.TEXT) {

                        const bellaUserId = localStorage.getItem("bellaUserId");
                        let userSendIsCurrent = false;
                        if(messageEvent?.publisher && bellaUserId && bellaUserId !== "" && 
                        messageEvent?.publisher === bellaUserId) {
                            userSendIsCurrent = true
                        }
                        if(messageEvent?.publisher) {
                            (async function () {
                                await populateUserChatList(messageEvent?.publisher);
                                const getContent = messageFromProtoObj.content.textMessageDetails.text
                                const msg: Message = {
                                    userId: messageEvent?.publisher,
                                    userName: "",
                                    userIcon: null, 
                                    message: getContent,
                                    timestamp: messageEvent?.timetoken,
                                    channel: messageEvent?.subscribedChannel,
                                    mexFromCurrentUser: userSendIsCurrent
                                }
        
                                if(messageEvent?.subscribedChannel !== ""){
                                    addMessageByChannel(msg, "instant")
                                }

                            })();
                        }

                    }

                } catch(error) {
                    console.log(error)
                }
            }
        },

        presence: (presenceEvent: any) => {
            // handle presence
            console.log("presence", presenceEvent)
        }
    };

    const pubnub = async (): Promise<void> => {
        console.log("chat -> pubnub");
        const chatPubnub = await checkChatConnection() as Pubnub;

        console.log();
        if (chatPubnub !== null) {
            pubnubSettings(chatPubnub);
            setPubnubChat(chatPubnub);
        }

    };

    const pubnubDestroy = async (): Promise<void> => {
        console.log("pubnubDestroy -> pubnub");
        if (pubnubChat !== null) {
            await pubnubChat.destroy();
            pubnubSettings(null);
            setPubnubChat(null);
        }

    };

    const pubnubSettings = async (chatPubnub: Pubnub | null) => {
        console.log("chat -> pubnub setting");
        if (chatPubnub !== null) {

            chatPubnub.addListener(listener);
            console.log("end addListener -> pubnub");

            let messagingToken = null;
            if (state?.bellaUser?.messaging?.token) {
                messagingToken = state?.bellaUser?.messaging;
            } else {
                const getMessaging = localStorage.getItem("messaging");
                if (getMessaging && getMessaging !== "") {
                    messagingToken = JSON.parse(getMessaging);
                    console.log("messagingToken", messagingToken)
                    addMessaging(JSON.parse(getMessaging));
                }
            }
            setNewToken(chatPubnub, messagingToken);
            setChannels(chatPubnub)
        }
    };

    const setNewToken = async (chatPubnub: Pubnub, messagingToken: any): Promise<void> => {
        
        console.log("messagingToken",messagingToken);
        if (chatPubnub !== null) {

            try {

                if (messagingToken) {
                    chatPubnub.setToken(messagingToken?.token);
                }
    
            } catch (status) {
                console.log("operation failed w/ error:", status);
            }
        }
    };

    const setChannels = async (chatPubnub: Pubnub): Promise<void> => {
        
        if (chatPubnub !== null) {
            try {
                const result = await chatPubnub.objects.getMemberships();
                const channelsId: any[] = [];
                if (result?.data) {
                    result?.data?.forEach((item: any, index: number) => {
                        channelsId[index] = item.channel.id;
                    });
                }

                const getBellaUserId = localStorage.getItem("bellaUserId");
                if(channelsId?.length > 0) {
                    const last = (channelsId?.length)-1;
                    channelsId[last+1] = `user.management.${getBellaUserId}`;
                    channelsId[last+2] = `user.management.${getBellaUserId}`;
                } else {
                    channelsId.push(`user.management.${getBellaUserId}`)
                    channelsId.push(`user.management.${getBellaUserId}`)
                }

                console.log("channelsId",channelsId);
                console.log("lenght channel",channelsId.length);

                //unsubscribe all
                chatPubnub.unsubscribeAll();
                // subscribe to channels list from result here
                chatPubnub.subscribe({
                    channels: channelsId,
                });
    
            } catch (status) {
                console.log("operation failed w/ error:", status);
            }
        }
    };

    const getNewToken = async (chatPubnub: Pubnub): Promise<any> => {

        const getBellaUserId = localStorage.getItem("bellaUserId");
        const getAccessToken = localStorage.getItem("accessToken");

        if (getBellaUserId && getBellaUserId !== "" && getAccessToken && getAccessToken !== "") {

            getMessagingToken(getBellaUserId, getAccessToken)
            .then(async (res: any) => {
                if(res?.status === 401){
                    disconnect();
                    navigate(routes.root)
                }
                else if(res?.data){
                  const getMessaging = {
                    token: res?.data?.token,
                    publisherKey: res?.data?.publisherKey,
                    subscriberKey: res?.data?.subscriberKey,
                    isNew: res?.data?.isNew
                  }

                  console.log("getMessaging",getMessaging);
                  localStorage.setItem("waiting-response","false");
                  // ovveride session messaging pubnub
                  addMessaging(getMessaging);
                  //set new token
                  setNewToken(chatPubnub, getMessaging);
                }
            })
            .catch((error) => {
                // ERROR
                console.log(error);
            });
            
        }

        return null;
    }

    const populateUserChatList = async (user: any) => {

        let payload: any = [];
        if(!userIsInsideList(user)){
            payload.push(user);
            const accessToken = localStorage.getItem("accessToken");
            if(accessToken && accessToken !== "")
                await usersProfile(payload, accessToken)
                    .then(async (res: any) => {
                        if(res?.response?.status === 401){
                            disconnect();
                            navigate(routes.root)
                        }
                        else if(res?.data?.payload?.records){
                            userProfileListState(res?.data?.payload?.records[0], true);
                        }
                    })
                    .catch((error) => {
                        // ERROR
                        console.log(error);
                    });
        }
    }

    const userIsInsideList = (userId: any) => {
        if (state?.userProfileList && userId) {
            return Boolean(state?.userProfileList[userId]);
        }
        return false;
    };
    
    const userProfileListState = (item: any, saveOnLocal: boolean) => {
        if (item) {
            //if (saveOnLocal) localStorage.setItem("userProfileChatList", JSON.stringify(item));
            return dispatch({ type: "ADD_USER_PROFILE_CHAT", payload : item});
        }
    };

    const createProtobufTextMessage = async (textMessage: any): Promise<any> => {

        const message = new messagePB.Message();
        const content = new contentPB.Content();
        const textMessageDetails = new textMessageDetailsPB.TextMessageDetails();
        textMessageDetails.setText(textMessage);
        content.setTextMessageDetails(textMessageDetails);
        message.setMessageType(messagePB.Message.MessageType.TEXT);
        message.setContent(content);
        return JSON.stringify({protobuf: Buffer.from(message.serializeBinary()).toString('base64')});
    };

    const setInfoHistoryMessage = async (message: any, channelId: string) => {

        try{
            const test = new Uint8Array(Buffer.from(message?.protobuf, 'base64'));
            const messageFromProto = new messagePB.Message(test);
            const messageFromProto2 = messagePB.Message.deserializeBinary(test);
            const messageFromProtoObj = messageFromProto2.toObject();

            if (messageFromProtoObj.messageType === messagePB.Message.MessageType.TEXT) {

                if(message?.senderId) {

                    populateUserChatList(message?.senderId);

                    const bellaUserId = localStorage.getItem("bellaUserId");
                    let userSendIsCurrent = false;
                    if(message?.senderId && bellaUserId && bellaUserId !== "" && 
                    message?.senderId === bellaUserId) {
                        userSendIsCurrent = true
                    }

                    const getContent = messageFromProtoObj.content.textMessageDetails.text
                    const msg: Message = {
                        userId: message?.senderId,
                        userName: "",
                        userIcon: null,
                        message: getContent,
                        timestamp: message?.timetoken,
                        channel: channelId,
                        mexFromCurrentUser: userSendIsCurrent
                    }

                    if(channelId !== ""){
                        addMessageByChannel(msg, "history")
                    }
                }
            }
        } catch(error) {
            console.log(error)
        }

    };
    
    // chat logic ends

    const resetListState = () => {
        localStorage.setItem("selectedCommunityFeed", "");
        localStorage.setItem("communityTokenList", "");
        localStorage.setItem("userChannelTokenList", "");
        return dispatch({ type: "RESET_LIST_STATE" });
    };

    const resetCommunityTokenList = () => {
        localStorage.setItem("communityTokenList", "");
        localStorage.setItem("userChannelTokenList", "");

        return dispatch({ type: "RESET_TOKEN_LIST_STATE" });
    };

    const resetState = () => {
        console.log("reset state");
        return dispatch({ type: "RESET_INITIAL_STATE", payload: INITIAL_STATE });
    };

    const addBellaUser = (item: any) => {
        localStorage.setItem("bellaUserId", item.bellaUserId);
        localStorage.setItem("accessToken", item.accessToken);
        localStorage.setItem("messaging", JSON.stringify(item.messaging));
        localStorage.setItem("refreshToken", item.refreshToken);
        return dispatch({ type: "ADD_USER", payload: item });
    };

    const addMessaging = (item: any) => {
        localStorage.setItem("messaging", JSON.stringify(item));
        return dispatch({ type: "ADD_MESSAGING", payload: item });
    };

    // added list of feeds
    const addCommunityFeedList = (item: any) => {
        return dispatch({ type: "ADD_COMMUNITY_FEED_LIST", payload: item });
    }

    // save selected feed
    const selectCommunityFeed = (item: any, saveOnLocal: boolean) => {
        if (item) {
            if (saveOnLocal) localStorage.setItem("selectedCommunityFeed", JSON.stringify(item));
            return dispatch({ type: "SET_COMMUNITY_FEED", payload: item });
        }
    }

    // save list of community product
    const addCommunityTokenList = (item: any, saveOnLocal: boolean) => {
        if (item) {
            if (saveOnLocal) localStorage.setItem("communityTokenList", JSON.stringify(item));
            return dispatch({ type: "ADD_COMMUNITY_TOKEN_LIST", payload: item });
        }
    }

    // save list of user community product
    const addUserChannelTokenList = (item: any, saveOnLocal: boolean) => {
        if (item) {
            if (saveOnLocal) localStorage.setItem("userChannelTokenList", JSON.stringify(item));
            return dispatch({ type: "ADD_USER_CHANNEL_TOKEN_LIST", payload: item });
        }
    }

    // save selected token
    const selectCommunityToken = (item: any) => {
        if (item)
            return dispatch({ type: "SET_COMMUNITY_TOKEN", payload: item });
    }

    // save wallet connection data
    const addWalletConnectInfo = (item: any, saveOnLocal: boolean) => {
        if (item) {
            if (saveOnLocal) localStorage.setItem("walletConnectInfo", JSON.stringify(item));
            return dispatch({ type: "ADD_WALLET_CONNECT_INFO", payload: item });
        }
    }

    // save wallet connection data
    const addPublicCommunityInfo = (item: any, saveOnLocal: boolean) => {
        if (item) {
            if (saveOnLocal) localStorage.setItem("publicCommunity", JSON.stringify(item));
            return dispatch({ type: "ADD_PUBLIC_COMMUNITY_INFO", payload: item });
        }
    }

    // save message
    const addMessageByChannel = (item: any, type :string) => {
        if (item) {
            return dispatch({ type: "ADD_MESSAGE_BY_CHANNEL", payload: [item, type] });
        }
    }

    const setError = (item: any) => {
        if (item) {
            return dispatch({ type: "SET_ERROR", payload: item });
        }
    }

    const resetError = (item: any) => {
        if (item) {
            return dispatch({ type: "RESET_ERROR", payload: item });
        }
    }

    // user profile
    const addUserProfile = (item: any) => {
        if (item) {
            return dispatch({ type: "ADD_USER_PROFILE", payload: item });
        } 
    }

    const addPrivateChannelsList = (item: any) => {
        return dispatch({ type: "ADD_PRIVATE_CHANNELS_LIST", payload: item });
    }

    const addTokensList = (item: any) => {
        //  console.log("hhh");
        if (item) {
            //   console.log(item);
            return dispatch({ type: "ADD_TOKENS_LIST", payload: item });
        }
    }

    const addTransactionsList = (item: any) => {
        return dispatch({ type: "ADD_TRANSACTIONS_LIST", payload: item });
    }

    const addNftList = (item: any) => {
        return dispatch({ type: "ADD_NFT_LIST", payload: item });
    }



    const providerValue = {
        state: state,
        walletConnector: connector,
        connect,
        disconnect,
        checkChainAndNetwork,
        signMessage,
        resetListState,
        resetCommunityTokenList,
        addBellaUser,
        checkWalletConnection,
        addCommunityFeedList,
        selectCommunityFeed,
        addCommunityTokenList,
        addUserChannelTokenList,
        selectCommunityToken,
        addWalletConnectInfo,
        isAuthenticate,
        pubNubChatConnector: pubnubChat,
        pubnub,
        pubnubDestroy,
        checkChatConnection,
        addPublicCommunityInfo,
        createProtobufTextMessage,
        setInfoHistoryMessage,
        setError,
        resetError,
        resetState,
        addUserProfile,
        addPrivateChannelsList,
        addTokensList,
        addTransactionsList,
        addNftList
    };

    return (
        <>
            <AppContext.Provider value={providerValue}>
                {children}
            </AppContext.Provider>
        </>
    );
};