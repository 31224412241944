import React, { useContext, useEffect, useState } from "react";
import Page from "@/layouts/Page";
import LoaderAnimation from "@/components/Lottie/loader";

import { useNavigate, useParams } from "react-router-dom";
import { appIcon } from "@/config/svg"; // placeholder img, to be replaced with correct item thumbnail
import { AppContext } from "@/context/createContext";
import { currencyConversionRate } from '@/services/index';
import routes from "@/config/routes";
import BellWeb3 from "@/services/web3";
import FadeIn from "react-fade-in";
import { polygon, ethereum } from "@/config/svg";
const web3Bella = new BellWeb3();

const initMessage = { title: '', message: '' };
const TokenCheckout: React.FC = () => {


    const { state, walletConnector, checkWalletConnection, connect, isAuthenticate, selectCommunityFeed, disconnect, checkChainAndNetwork } = useContext(AppContext);
    const params = useParams();
    const [selectedCommunityToken, setSelectedCommunityToken] = useState<any>(null);
    const [loader, stopAnimation] = useState(false);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [currencyRateUsd, setCurrencyRateUsd] = useState<any>(null);
    const [gasFee, setGasFee] = useState<any>(null);
    const [showCorrectNetworkPopup, setshowCorrectNetworkPopup] = useState(false);
    const [showMessagePopup, setshowMessagePopup] = useState(false);
    const [message, setMessage] = useState(initMessage);
    const [showBalanceMessage, setShowBalanceMessage] = useState(false);

    let navigate = useNavigate();
    const [web3ready, setWeb3Ready] = useState(true);
    const [disabledbtn, setDisabledbtn] = useState<boolean>(false);
    const  [checkoutLoader, setCheckoutLoader] = useState(false);

    useEffect(() => {
        console.log(disabledbtn);
        document.body.className = "checkout-page";
        return () => {
            document.body.className = "";
        };
    }, []);

    useEffect(() => {
        //check if I have a wallet connection
        if (walletConnector === null) {

            checkWalletConnection('boolean').then(async (isConnected) => {
                if (isConnected) {
                    await connect();
                } else {
                    // guest user - silent is golden
                }
            })
        }

        //if I have a wallet connection - logged user
        if (walletConnector !== null) {

            (async function () {
                if (await isAuthenticate()) {
                    setIsUserAuthenticated(true);
                }
                else {
                    disconnect(); //disconnect wallet connect session
                }
            })();

        }

    });


    useEffect(() => {
        if (selectedCommunityToken === null) {

            if (state?.communityTokenList && state?.communityTokenList?.length > 0) {
                // setto prodotto
                const t = getTokenFromList(state?.communityTokenList, params?.id);
                setSelectedCommunityToken(t);

            } else {
                const getCommunityTokenList = localStorage.getItem("communityTokenList");
                if (getCommunityTokenList && getCommunityTokenList !== "") {
                    //setto prodotto
                    const t = getTokenFromList(JSON.parse(getCommunityTokenList), params?.id);
                    setSelectedCommunityToken(t);
                    selectCommunityFeed(t, false); // save info again on state

                }
            }
        } else if (selectedCommunityToken !== null && web3ready === true && state?.walletConnectInfo?.accounts[0]) {
            //console.log('..................');
            web3Bella.setFromAddress(state?.walletConnectInfo?.accounts[0])
            web3Bella.updateNetwork(selectedCommunityToken.chain, selectedCommunityToken.network);

            // const channelId = 'private.c077d475-eeb4-4048-88e9-dab6a709ef95.db558f43-a0d0-40bb-b23b-02e6b0b57868';
            web3Bella.getGasEstimation(selectedCommunityToken.channelId).then(
                (res) => {
                    if (!(res).includes('insufficient funds')) {
                        setGasFee(Number(res).toFixed(6));
                        stopAnimation(true);
                    } else {
                        stopAnimation(true);
                        setMessage({ ...initMessage, title: "We're sorry", message: "You don't have enough funds to cover the token and gas fees. Please add more funds to your wallet and try again." });
                        setShowBalanceMessage(true);

                    }

                });
            currencyConversionRate(selectedCommunityToken?.chain).then(res => setCurrencyRateUsd(res));
            //console.log('..................');
            setWeb3Ready(false);

        }

    }, [params?.id, selectCommunityFeed, selectedCommunityToken, state?.communityTokenList]);

    useEffect(() => {
        //console.log('checkChainAndNetwork----');

        if (state?.walletConnectInfo && walletConnector && state?.walletConnectInfo?.chainId && selectedCommunityToken !== null) {
            setshowCorrectNetworkPopup(!checkChainAndNetwork(selectedCommunityToken.chain, selectedCommunityToken.network))
        }
    }, [state?.walletConnectInfo]);


    function getTokenFromList(list: any[], channelId: string | undefined) {
        return list.find((element) => {
            return element.channelId === channelId;
        })
    }

    const convertCurrencyToUsd = (cryptoPrice: any | null) => {
        if (currencyRateUsd && cryptoPrice !== null) {
            const calculatedPriceUsd = cryptoPrice * currencyRateUsd;
            return '$' + (calculatedPriceUsd).toFixed(6);
        }
    }

    const totalPrice = () => {
        if(selectedCommunityToken?.tokenPrice !== null && gasFee !== null){
           const total = Number(selectedCommunityToken?.tokenPrice) + Number(gasFee);
           return total.toFixed(6);
        } 
    }

    const currencyImage = (currency: string) => {
        return (currency === "POLYGON") ? polygon : ethereum;
    };

    const makePurchase = async () => {
        setCheckoutLoader(true)

        web3Bella.setFromAddress(state?.walletConnectInfo?.accounts[0])
        web3Bella.updateNetwork(selectedCommunityToken.chain, selectedCommunityToken.network);

        if (state?.walletConnectInfo && walletConnector && state?.walletConnectInfo?.chainId) {
            if (checkChainAndNetwork(selectedCommunityToken.chain, selectedCommunityToken.network)) {
                setDisabledbtn(true);
                try {
                    const result = await web3Bella.sendTransaction(walletConnector, selectedCommunityToken.channelId)
                    console.log({ result });

                    navigate('/success/' + result);

                } catch (error: any) {
                    setCheckoutLoader(false)
                    console.log({ error });
                    setMessage({ ...initMessage, title: 'Error', message: error?.message });
                    setshowMessagePopup(true);
                    setDisabledbtn(false);
                }

                /*  .then((result:any) => {
                     console.log({transectionResult:result});                  
                     
                     navigate('/success');
                 })
                 .catch((error:any) => {
                     // Error returned when rejected
                     console.error(error);
                     setDisabledbtn(false); 
                 }); */
            } else {
                setCheckoutLoader(false)
                setshowCorrectNetworkPopup(!checkChainAndNetwork(selectedCommunityToken.chain, selectedCommunityToken.network))
            }
        }
    }


    const removeMessage = () => {
        setMessage(initMessage);
        setshowMessagePopup(false);
    }
    const CancelButton = () => {
        return (
            <button className="black-btn super-small-bnt custom-mt-15" disabled={disabledbtn} onClick={() => navigate(routes.root)}>
                <p className="btn-text ">Cancel</p>
            </button>
        )
    }
    const ConfirmButton = () => {
        return (
            <button className="custom-btn small-bnt" onClick={() => makePurchase()}>
                <p className="btn-text ">Confirm checkout</p>

            </button>
        )
    }

    return (
        <>
            {!loader || selectedCommunityToken == null ? (
                <section className="community-section h-100">
                    <div className="container h-100 d-flex justify-content-center align-items-center">
                        <LoaderAnimation height={100} width={100} />
                    </div>
                </section>
            ) : (
                <>
                    <Page>

                        <section className=" h-100 flex-column d-flex flex-grow-1">
                            <div className="container flex-column d-flex flex-grow-1">
                                <div className=" d-flex flex-column flex-grow-1">
                                    <div className="custom-mt-55 d-flex flex-column justify-content-center align-items-center custom-mb-40 text-center">
                                        <h1 className="rainbow-title-page custom-mb-10">COMPLETE CHECKOUT</h1>
                                        <p className="light-text grey custom-mt-10">Get ready to join the community!</p>
                                    </div>
                                    {showBalanceMessage ?
                                        <FadeIn transitionDuration={600}>
                                            <div className="popup-overlay">
                                                <div className="popup-inner">
                                                    <div className="popup-content text-center">
                                                        <p className="medium-text custom-mb-20 custom-pt-10">{message.title}</p>
                                                        <p className=" regular-text-small">{message.message}</p>
                                                        <button className="black-btn super-small-bnt custom-mt-15" onClick={() => navigate(routes.root)}>
                                                            <p className="btn-text ">Cancel</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </FadeIn>
                                        :
                                        <div className="table_wrapper custom-pl-20 custom-pr-20">
                                            <div className="d-flex custom-pb-15 custom-pb-15 border-bottom-grey align-items-start">
                                                <div className="d-flex flex-grow-1">
                                                    <div className="item-thumbnail custom-mr-10" style={{ backgroundImage: `url(${selectedCommunityToken?.iconUrl})` }}></div>

                                                    <div className="d-flex flex-column custom-pr-20">
                                                        <h2 className="medium-text-large custom-mb-5">{selectedCommunityToken?.channelName}</h2>
                                                        <p className="grey light-text">By <span className="white regular-text">{selectedCommunityToken?.creatorName}</span></p>
                                                    </div>
                                                </div>
                                                <div className="text-right"><p className="medium-text custom-mb-10"><img className={
                                                    selectedCommunityToken?.tokenPrice
                                                        ? "custom-mr-10 icon-currency"
                                                        : "hide"
                                                } src={currencyImage(selectedCommunityToken?.chain)} alt="icon" />{selectedCommunityToken?.tokenPrice}</p><p className="grey light-text">{convertCurrencyToUsd(selectedCommunityToken?.tokenPrice)}</p></div>
                                            </div>
                                            <div className="d-flex custom-pt-15 custom-pb-15 border-bottom-grey align-items-start">
                                                <div className="d-flex flex-grow-1 custom-pr-20">
                                                    <div className="d-flex flex-column">
                                                        <p className="regular-text custom-mb-10">Estimated Gas Fees</p>
                                                        <p className="grey light-text">Actual gas fees may change and are not paid to BELLA.</p>
                                                    </div>
                                                </div>
                                                <div className="text-right"><p className="medium-text custom-mb-10">{gasFee}</p><p className="grey light-text">{convertCurrencyToUsd(gasFee)}</p></div>


                                            </div>
                                            <div className="d-flex custom-pt-35 align-items-start">
                                                <div className="d-flex flex-grow-1 custom-pr-20">
                                                    <p className="regular-text">TOTAL</p></div>

                                                <div className="text-right"><p className="medium-text-large custom-mb-10"><img className={
                                                    totalPrice()
                                                        ? "custom-mr-10 icon-currency"
                                                        : "hide"
                                                } src={currencyImage(selectedCommunityToken?.chain)} alt="icon" />{totalPrice()}</p><p className="grey light-text">{convertCurrencyToUsd(totalPrice())}</p></div>
                                            </div>
                                        </div>
                                    }

                                </div>

                                {!showBalanceMessage && (checkoutLoader ? <LoaderAnimation height={100} width={100} />:<div className="d-flex flex-column align-items-center custom-pb-40">
                                    {!showCorrectNetworkPopup && <ConfirmButton />}
                                    {<CancelButton />}
                                </div>)
                                }


                            </div>
                            {showCorrectNetworkPopup ?
                                <FadeIn transitionDuration={600}>
                                    <div className="popup-overlay">
                                        <div className="popup-inner">
                                            <div className="popup-content text-center">
                                                <p className="medium-text custom-mb-20 custom-pt-10">Switch active network</p>
                                                <p className=" regular-text-small">You need to switch the active network in your connected wallet to {selectedCommunityToken?.network} before you can continue. Please open your wallet app, change the active network to {selectedCommunityToken?.network}, then come back to Bella and try again.</p>
                                                {<CancelButton />}

                                            </div>
                                        </div>
                                    </div>
                                </FadeIn>
                                : null}
                            {showMessagePopup ?
                                <FadeIn transitionDuration={600}>
                                    <div className="popup-overlay">
                                        <div className="popup-inner">
                                            <div className="popup-content text-center">
                                                <p className="medium-text custom-mb-20 custom-pt-10">{message.title}</p>
                                                <p className=" regular-text-small">{message.message}</p>
                                                <button className="black-btn super-small-bnt custom-mt-15" onClick={removeMessage}>
                                                    <p className="btn-text ">OK</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </FadeIn>
                                : null}

                        </section>

                    </Page>
                </>
            )}
        </>
    )
};

export default TokenCheckout;


