import routes from "@/config/routes";
import { account, close, vector } from "@/config/svg";
import { AppContext } from "@/context/createContext";
import { useFindPath } from "@/helper/common";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Header(): JSX.Element {

  const { state, selectCommunityFeed, addPublicCommunityInfo } = useContext(AppContext);
  const [selectedCommunityFeed, setSelectedCommunityFeed] = useState<any>(null);
  let navigate = useNavigate();
  const path = useFindPath();

  useEffect(() => {

    if(path !== "/") 

      if(path === "/chat") 

        if(state?.publicCommunity){
          setSelectedCommunityFeed(state?.publicCommunity);
        } else {
          const getPublicCommunity = localStorage.getItem("publicCommunity");
          if (getPublicCommunity && getPublicCommunity !== "") {
            setSelectedCommunityFeed(JSON.parse(getPublicCommunity));
            addPublicCommunityInfo(JSON.parse(getPublicCommunity), false); // save info again on state
          }
        }

      else

        if(state?.selectedCommunityFeed){
          setSelectedCommunityFeed(state?.selectedCommunityFeed);
        } else {
          const getSelectedCommunityFeed = localStorage.getItem("selectedCommunityFeed");
          if (getSelectedCommunityFeed && getSelectedCommunityFeed !== "") {
            setSelectedCommunityFeed(JSON.parse(getSelectedCommunityFeed));
            selectCommunityFeed(JSON.parse(getSelectedCommunityFeed), false); // save info again on state
          }
        }
        
  }, [addPublicCommunityInfo, path, selectCommunityFeed, state?.publicCommunity, state?.selectedCommunityFeed]);

  const handleClose = async () => {

    if(path === "/community/catalog") {
      navigate(routes.communitySelectedFeed);
    }
    else if(path?.includes("/tokens/")){
      navigate(routes.communityTokensList);
    } 
    else if(path?.includes("/delete-account")){
      navigate(routes.profile);
    } 
    else {
      navigate(routes.root);
    }
      
  };
  
  useEffect(() => {
    let closeWalletBtn = document.getElementById("walletconnect-qrcode-close");
    if(path !== "/wallet" && closeWalletBtn) {
      closeWalletBtn.click();
    }
  }, [path]);
  
  return (
    <div className="container-fluid header-container">
      <div className="icon-section position-relative d-flex align-items-center justify-content-center custom-mt-26">
        <div className="account position-absolute">
          <div className="border-gradient d-flex justify-content-center align-items-center">
              {path === "/" ? (
                <Link to={routes.profile} className="h-100 cursor-pointer">
                  <img className="custom-mt-10" src={account} alt="account" />
                </Link>
              ) : (
                <div onClick={() => handleClose()} className="h-100 cursor-pointer">
                  <img className="custom-mt-8" src={vector} alt="vector" />
                </div>
              )}
          </div>
        </div>
        <div className="title">
          <div className="d-flex ">
            {path === "/" ? (
              <h1 className="light-title-page">Welcome!</h1>
            ) : (
              <img className="logo-title-page" src={selectedCommunityFeed?.iconUrl} alt="logo" />
            )}
          </div>
        </div>
        <div className="close-section position-absolute cursor-pointer">
          <div onClick={() => handleClose()}>
            <img className="" src={close} alt="close" />
          </div>
        </div>
      </div>
    </div>
  );
}
