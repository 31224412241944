//import routes from "./config/routes";
//import { useEffect } from "react";
//import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import {
  Home,
  Wallet,
  CommunitySelectedFeed,
  CommunityTokensList,
  CommunityTokenDetails,
  Onboarding,
  Authenticate,
  TokenCheckout,
  Success,
  Profile,
  CommunityChat,
  DeleteAccount
} from "@/pages/index";
import routes from "./config/routes";

const AppRouter = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/community" element={<CommunitySelectedFeed />} />
    <Route path="/community/catalog" element={<CommunityTokensList />} />
     

    
    <Route path="/wallet" element={<Wallet />} />

    <Route path={routes.communitySelectedToken} element={<CommunityTokenDetails />} />


    <Route path="/onboarding" element={<Onboarding />} />
    <Route path="/authenticate" element={<Authenticate />} />
    <Route path={routes.tokenCheckout} element={<TokenCheckout />} />
    <Route path={routes.success} element={<Success />} />
    <Route path="/profile" element={<Profile />} />
    <Route path={routes.communityChat} element={<CommunityChat />} />
    <Route path="/delete-account" element={<DeleteAccount />} />
  </Routes>
);

export default AppRouter;
