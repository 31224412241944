// eslint-disable-next-line import/no-anonymous-default-export
export default {
    root: '/',
    wallet: '/wallet',
    communitySelectedFeed: '/community',
    communityTokensList: '/community/catalog',
    communitySelectedToken: '/tokens/:id',
    onboarding: '/onboarding',
    authenticate: '/authenticate',
    tokenCheckout: '/checkout/:id',
    success: '/success/:id',
    profile: '/profile',
    communityChat: '/chat',
    deleteAccount: '/delete-account'
}