import { AppContext } from '@/context/createContext';
import React, { useContext, useEffect, useState } from 'react';
import { chat, customers } from "@/config/svg";
import routes from '@/config/routes';
import { useNavigate } from 'react-router-dom';
import LoaderAnimation from "@/components/Lottie/loader";

import LazyLoad from "react-lazyload";
import FadeIn from "react-fade-in";
import { communityList } from '@/services/index';

const CommunityFeedList: React.FC = () => {

  let navigate = useNavigate();
  const [communityFeed, setcommunityFeed] = useState<any>([]);
  const [selectedCommunity, setSelectedCommunityFeed] = useState<any>(null);
  const { state, selectCommunityFeed, addCommunityFeedList, disconnect, resetCommunityTokenList, resetError, setError } = useContext(AppContext);
  const  [loader, stopAnimation] = useState(false);
  let resetListCount = 0;

  useEffect(() => {

    if(resetListCount === 0 && (state?.communityTokenList?.length > 0 || state?.userChannelTokenList?.length>0)){
      resetListCount++;
      resetCommunityTokenList();
      resetError(null);
    }

    document.body.className = "communityFeedList-page";
    return () => {
      document.body.className = "";
    };

  }, [resetCommunityTokenList, resetError, resetListCount, state]);

  useEffect(() => {

    // end condition
    if (communityFeed && communityFeed.length === 0) {

      // check if I have the info saved on context
      if (state?.communityFeedList && state?.communityFeedList?.length > 0) {
        setcommunityFeed(state?.communityFeedList);
        stopAnimation(true);

      } else {

        const bellaUserId = localStorage.getItem("bellaUserId");
        const accessToken = localStorage.getItem("accessToken");

        if (bellaUserId && bellaUserId !== "" && accessToken && accessToken !== "") {
          // guest user logged - call community services
          communityList(state?.bellaUser?.bellaUserId, state?.bellaUser?.accessToken)
            .then(async (res: any) => {
              if(res?.response?.status === 401){
                disconnect();
                window.location.reload();
              }
              else if (res?.name === 'AxiosError'){
                const error = {
                  visible: true,
                  text: res?.message,
                }
                setError(error);
              }
              else if(res?.data?.results){
                addCommunityFeedList(res.data.results);
                resetError(null);
              }
            })
            .catch((error) => {
              // ERROR
              console.log(error);
            });
        }
      }
    }

  }, [addCommunityFeedList, communityFeed, disconnect, resetError, setError, state]);

  const selectCommunity = async (item: any) => {
    if (item) {
      setSelectedCommunityFeed(item.communityId);
      selectCommunityFeed(item, true);
      console.log("selectCommunityFeed", item);
      navigate(routes.communitySelectedFeed);
    } 
  };

  return (
    <>
    {!loader || communityFeed === null ? (
      <div className="container h-100 d-flex justify-content-center align-items-center">
        <LoaderAnimation height={100} width={100} />
      </div>
    ) : (
      <>
      <div className="row community-container cursor-pointer">
        {communityFeed.length > 0 &&
        communityFeed.map((community: any, index: number) => (
          <LazyLoad height={200} once key={index.toString()}>
            <FadeIn transitionDuration={800}>
              <div
                className={
                  selectedCommunity === community.communityId
                    ? "active community-block"
                    : "community-block"
                }
                onClick={() => selectCommunity(community)}
              >
                <div className="community-header d-flex justify-content-start align-items-center">
                  <div className="img-container custom-mr-10">
                    <img src={community?.iconUrl} alt="logo-community" />
                  </div>
                  <h2 className="second-title-page">{community?.communityName}</h2>
                </div>
                <div className="community-body custom-mt-20 position-relative"
                  style={{ backgroundImage: `url(${community?.profileImageUrl})` }}>
                    <div className='box-info position-absolute w-100 d-flex align-items-center justify-content-between'>
                      <div className='custom-ml-10 d-flex align-items-center'>
                        <img src={customers} alt="customers" />
                        <p className="custom-ml-5">{community?.numberOfUsers}</p>
                      </div>
                      <div className='custom-mr-10 d-flex align-items-center'>
                        <img src={chat} alt="customers" />
                        <p className="custom-ml-5">{community?.numberOfMsgs}</p>
                      </div>
                    </div>
                </div>
                <div className="separator custom-mt-30 custom-mb-30"></div>
              </div>
            </FadeIn>
          </LazyLoad>
        ))}
      </div>
      </>
    )}
    </>
  );
};

export default CommunityFeedList;