import React, { useContext, useEffect, useState } from "react";
import RailPinkAnimation from "@/components/Lottie/railpink";
import Page from "@/layouts/Page";
import { AppContext } from "@/context/createContext";
import { nonceOnbording, authentication } from "../services";
import FadeIn from "react-fade-in";
import { infoIcon } from "@/config/svg";
import { useNavigate } from "react-router-dom";
import routes from "@/config/routes";

import { convertUtf8ToHex } from "@walletconnect/utils";
import web3Utils from 'web3-utils';

const Onboarding: React.FC = () => {
    let navigate = useNavigate();
    const { state, addBellaUser, walletConnector, checkWalletConnection, connect, signMessage, disconnect, resetError, setError } = useContext(AppContext);
    const [accountsID, setAccountsID] = useState<any>(false);
    const [onboarding, setOnboarding] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState(false);
    const [nonce, setNonce] = useState(null);

    useEffect(() => {

        if(walletConnector === null){
            checkWalletConnection('boolean').then(async (isConnected)=>{
                if(isConnected)
                    await connect();
                else                
                    navigate(routes.root);
            })
            setAccountsID(false)
        } 
        
        if(walletConnector !== null && walletConnector.connected) {

            console.log("accountsID: ",accountsID);
            console.log({walletConnectInfo:state?.walletConnectInfo});
            if(!accountsID){
                if(state?.walletConnectInfo != null){
                    setAccountsID(state?.walletConnectInfo?.accounts);
                } 

            } else {

                console.log("onboarding: ", onboarding);
                if (!onboarding){
            
                    const bellaUserId = localStorage.getItem("bellaUserId");
                    const accessToken = localStorage.getItem("accessToken");
            
                    if (bellaUserId !=="" && accessToken !=="") {
                        const hash = web3Utils.toChecksumAddress(accountsID[0]);
                        nonceOnbording(bellaUserId, accessToken, hash)
                            .then(async (res: any) => {
                                if(res?.response?.status === 401){
                                    disconnect();
                                    window.location.reload();
                                }
                                else if (res?.name === 'AxiosError'){
                                    const error = {
                                        visible: true,
                                        text: res?.message,
                                    }
                                    setError(error);
                                }
                                else if(res?.data){
                                    console.log(res?.data);
                                    setNonce(res?.data?.nonce);
                                    setOnboarding(true);
                                    resetError(null);
                                }
                            })
                            .catch((error: any) => {
                                // ERROR
                                console.log(error);
                            });
            
                    } else 
                        console.log("ERROR DATA NOT FOUND");
                }
            }
        }

    }, [accountsID, checkWalletConnection, connect, disconnect, navigate, onboarding, resetError, setError, state, walletConnector]);

    const handleAuthentication = async () => {

        if(nonce !== null && accountsID[0] !== null){

            const hash = web3Utils.toChecksumAddress(accountsID[0]);
            console.log(convertUtf8ToHex(nonce));

            const payload = [
                convertUtf8ToHex(nonce),
                hash
            ];

            signMessage(payload)
                .then((signedNonce: any) => {

                    if(signedNonce && signedNonce !== ""){

                        const bellaUserId = localStorage.getItem("bellaUserId");
                        const accessToken = localStorage.getItem("accessToken");
            
                        if (bellaUserId !=="" && accessToken !=="") {
                        
                            authentication(bellaUserId, accessToken, nonce, hash, signedNonce)
                                .then(async (res: any) => {
                                    if(res?.data){
                                        const answer = res?.data;
                                        const bellaUser = {
                                            bellaUserId: answer?.bellaUserId,
                                            accessToken: answer?.accessToken,
                                            refreshToken: answer?.refreshToken,
                                            messaging: answer?.messaging
                                        }
                                        addBellaUser(bellaUser); 
                                        navigate(routes.authenticate);
                                    }
                                })
                                .catch((error: any) => {
                                    // ERROR
                                    console.log(error);
                                });
                        }
                    }
                })
                .catch((error: any) => {
                    // ERROR
                    console.log(error);
                });
        }
    };

    useEffect(() => {
      document.body.className = "onboarding-page";
      return () => {
          document.body.className = "";
      };
    }, []); 
  
    return (
        <>
            <Page>
               
                <section className=" h-100 ">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="custom-mt-55 d-flex flex-column justify-content-center align-items-center custom-mb-60 text-center">
                                <h1 className="rainbow-title-page custom-mb-40">SIGN HERE, PLEASE!</h1>
                                <p className="custom-pr-10 custom-pl-10 medium-text-large custom-mb-26">You're almost done</p>
                                <p className="custom-pr-10 custom-pl-10 medium-text">Tap the button below and open your wallet app to sign the message we just sent.</p>
                                <div className="row custom-mt-60"><p className="custom-pr-10 custom-pl-10 light-text-small custom-mb-26"><span>What is signing with a wallet?</span>
                                    <button className="info-btn custom-ml-10" onClick={() => setShowPopup(true)}><img src={infoIcon} alt="information" /></button></p> 
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center  text-center">
                                <button className="cursor-pointer custom-btn small-bnt custom-mb-30" onClick={() => handleAuthentication()}>
                                    <p className="btn-text ">Sign message</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="lottie-rail-wrapper">
                        <RailPinkAnimation />
                    </div>
                </section>
                { showPopup ? 
                    <FadeIn transitionDuration={600}>
                        <div className="popup-overlay">
                            <div className="popup-inner">
                                <div className="popup-content text-center">
                                <p className="medium-text custom-mb-20 custom-pt-10">Message signature, why?</p>
                                <p className=" regular-text-small">Signing a message helps prove your ownership and prevent transactions from being altered by anyone else.</p>
                                <button className="custom-btn super-small-bnt custom-mt-40" onClick={() => setShowPopup(false)}>
                                    <p className="btn-text ">Got it!</p>
                                </button>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                : null }
            </Page>
        </>
    )
  };
  
  export default Onboarding;


