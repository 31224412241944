import Page from "@/layouts/Page";
import LoaderAnimation from "@/components/Lottie/loader";
import { useContext, useEffect, useState, useRef } from "react";
import { angleDx, angleSx, arrowDown, customers } from "@/config/svg";
import { AppContext } from "@/context/createContext";
import { timestampToTime } from "@/helper/common";
import { Scrollbars } from 'react-custom-scrollbars';
import { getHistoryMessage, sendMessage } from "@/services/index";
import routes from "@/config/routes";
import { useNavigate } from "react-router-dom";


const CommunityChat: React.FC = () => {

    const { state, addPublicCommunityInfo, pubNubChatConnector, pubnub, checkChatConnection, createProtobufTextMessage, 
        disconnect, setError, resetError, setInfoHistoryMessage } = useContext(AppContext);
    const [loader, stopAnimation] = useState(false);
    const [selectedChat, setSelectedChat] = useState<any>(null);
    const [showDescription, isDescriptionVisible] = useState<boolean>(true);
    const [messageChatList, setMessageChatList] = useState<any>([]);
    
    const [historyMessageChatList, setHistoryMessageChatList] = useState<any>(null);
    const [counter, setCounter] = useState<number>(0);

    const [userCanWrite, setUserCanWrite] = useState<boolean>(false);
    const [message, setMessage] = useState<any>("");
    const [sendedMessage, setSendedMessage] = useState<any>("");
    const bottomRef = useRef<null | HTMLDivElement>(null);
    let navigate = useNavigate();

    useEffect(() => {
        document.body.className = "communityChat-page";
        return () => {
            document.body.className = "";
        };
    }, []);

    useEffect(() => {

        // end condition
        if (selectedChat === null) {

            // check if I have the info saved on context
            if (state?.publicCommunity) {
                setSelectedChat(state?.publicCommunity);
                //stopAnimation(true);
            } else {
                // check if I have the info saved on session
                const getPublicCommunity = localStorage.getItem("publicCommunity");
                if (getPublicCommunity && getPublicCommunity !== "") {
                    addPublicCommunityInfo(JSON.parse(getPublicCommunity), false); // save info again on state
                }
            }
        }

    }, [addPublicCommunityInfo, selectedChat, state?.publicCommunity]);
    
    useEffect(() => {

        // end condition
        if (selectedChat !== null && historyMessageChatList === null) {

            async function fetchData() {
                const bellaUserId = localStorage.getItem("bellaUserId");
                const accessToken = localStorage.getItem("accessToken");
                const messaging = localStorage.getItem("messaging");

                if (bellaUserId && bellaUserId !== "" && accessToken && accessToken !== "" &&
                messaging && messaging !== "" && selectedChat) {  
    
                    try {
                        const [historyMessage] =  await Promise.all([
                            await getHistoryMessage(bellaUserId, selectedChat.channelId, accessToken, JSON.parse(messaging)?.token)
                        ])
                        setHistoryMessageChatList(historyMessage);
                    }
                    catch(error: any) {
                        // ERROR
                        console.log(error);
                    }
                }
            }        
            fetchData();
        }
    
    }, [disconnect, historyMessageChatList, resetError, selectedChat, setError, setInfoHistoryMessage, state]);

    useEffect(() => {

        //console.log("historyMessageChatList", historyMessageChatList)
        //console.log("counter", counter)
        if(historyMessageChatList !== null && counter === 0){

            if(historyMessageChatList?.response?.status === 401){
                disconnect();
                window.location.reload();
            }
            else if (historyMessageChatList?.name === 'AxiosError'){
                const error = {
                    visible: true,
                    text: historyMessageChatList?.message,
                }
                setError(error);
                stopAnimation(true);
            }
            else if(historyMessageChatList?.data?.messages){
                resetError(null);
                if(historyMessageChatList?.data?.messages?.length > 0)
                    historyMessageChatList?.data?.messages.forEach((message: any, index: number) => {
                        if(message?.isDeleted) {
                            //silent is golden
                        }
                        else setInfoHistoryMessage(message, historyMessageChatList.data.channelId);
                    })
                
            }
            setCounter(counter+1);
            
        }
    }, [counter, disconnect, historyMessageChatList, resetError, setError, setInfoHistoryMessage]);

    useEffect(() => {

        // end condition
        //console.log("selectedChat", selectedChat)
        if (selectedChat !== null) {

            console.log("pubNubChatConnector", pubNubChatConnector)
            if (pubNubChatConnector === null) {
                checkChatConnection('boolean').then(async (haveChat) => {
                    if (haveChat) {
                        await pubnub();
                    }
                })
            }

            if (pubNubChatConnector !== null) {

                // check if I have the info saved on context
                if (selectedChat && selectedChat !== null && selectedChat.isMember && selectedChat.channelId !== "public.bella.main") {
                    setUserCanWrite(true);
                }
                //set user profile on history messages
                if (state?.messageList && state?.userProfileList) {
                    const chatMessage = (state?.messageList)[selectedChat.channelId];
                    if(chatMessage)
                        chatMessage.map((message: any) => {
                            const user = state?.userProfileList[message.userId]
                            if(user){
                                message["userName"] = user.alias;
                                message["userIcon"] = user.avatarUrl;
                            }
                            return message;
                        })
                    setMessageChatList(chatMessage);
                   
                }

                stopAnimation(true);  
                //go to the last message wriiten by user
                if(sendedMessage !== ""){
                    setTimeout(() => {
                        handlerScrollBottom();
                    }, 100);
                }
            } 
        }

    }, [checkChatConnection, message, messageChatList, pubNubChatConnector, pubnub, selectedChat, sendedMessage, state]);

    function handleChange(event: any) {
        event.preventDefault();
        setMessage(event.target.value)
    }

    function handlerScrollBottom(){
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }

    const handlerSendMessage = async () => {

        stopAnimation(false);
        const protobufMessage = JSON.parse(await createProtobufTextMessage(message));

        const bellaUserId = localStorage.getItem("bellaUserId");
        const accessToken = localStorage.getItem("accessToken");
        const messaging = localStorage.getItem("messaging");

        if (bellaUserId && bellaUserId !== "" && accessToken && accessToken !== "" &&
        messaging && messaging !== "" && selectedChat) { 

            setSendedMessage(JSON.parse(messaging)?.token)
            sendMessage(bellaUserId, selectedChat.channelId, accessToken, JSON.parse(messaging)?.token, protobufMessage.protobuf)
                .then(async (res: any) => {                    
                    if (res?.response?.status === 401) {
                        disconnect();
                        navigate(routes.root)
                    }
                    else {
                        if (res?.data) {
                            console.log("sendMessage", res?.data)
                            stopAnimation(true);
                            setMessage("");
                        }
                    }
            })
            .catch((error) => {
                // ERROR
                console.log(error)
            });
        }
    };

    return (
        <>
        {!loader ? (
        <section className="community-section h-100">
          <div className="container h-100 d-flex justify-content-center align-items-center">
            <LoaderAnimation height={100} width={100} />
          </div>
        </section>
      ) : (
        <>
          <Page>
            <section className="community-section h-100 d-flex flex-column flex-grow-1">
                <div className="container h-100 d-flex flex-column flex-grow-1">
                    <div className="row h-100 d-flex flex-column flex-grow-1">
                        <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
                            <div className="w-100 item-chat-block d-flex flex-column flex-grow-1 position-relative">
                                <div className="item-title custom-mt-20 custom-mb-20">
                                    <div className="d-flex justify-content-center align-items-center cursor-pointer"
                                        onClick={() => isDescriptionVisible(!showDescription)}>
                                        <h1
                                            className="medium-title-page custom-mr-10">
                                            {selectedChat?.channelName}
                                        </h1>
                                        <img className={!showDescription ? "arrow-up" : "arrow-down"} src={arrowDown} alt="arrow down" />
                                    </div>
                                    {showDescription ? <div className="description-title text-center custom-mt-20 position-relative">
                                        <p className="regular-text">{selectedChat?.description}</p>
                                        <img className="position-absolute angle-sx" src={angleSx} alt="angle-sx icon" />
                                        <img className="position-absolute angle-dx" src={angleDx} alt="angle-dx icon" />
                                    </div> : <></>}
                                </div>
                                
                                <div className="d-flex align-items-center justify-content-center">
                                    <p className="light-text text-left">
                                    <img className="custom-mr-10" src={customers} alt="icon" />
                                        {selectedChat?.numberOfParticipants} 
                                    </p>
                                </div>

                                <div className="separator custom-mt-30 custom-mb-30"></div>

                                <div className="text-center custom-mb-30">
                                    <span className="light-text-small grey text-center">Today</span>
                                </div>
                                <div className="chat-container flex-grow-1">
                                    <Scrollbars className="custom-scrollbar">
                                    <div className="chat-messages-wrapper d-flex flex-column custom-pb-10">

                                    { messageChatList && messageChatList.length > 0 &&
                                        messageChatList.slice().reverse().map((message: any, index: number) => (
                                        <div key={index.toString()} className={
                                            (message.mexFromCurrentUser) ? 
                                                "currentUser chat-message-element custom-mb-20" 
                                                : "chat-message-element custom-mb-20"}>
                                            <div className="d-flex align-items-end">
                                                <img src={message.userIcon} className="user-profile-icon" alt="user profile" />
                                                <div className="custom-mr-10 custom-ml-10">
                                                    <div className="chat-bubble">
                                                        <p className="regular-text">{message.message}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex custom-ml-30 custom-mt-10 align-items-end">
                                                <div className="d-flex justify-content-start w-100 custom-pl-10 chat-info">
                                                    <p className="light-text-very-small grey custom-mr-10">{ message.userName }</p>
                                                    <p className="light-text-very-small custom-mr-10">{timestampToTime(message.timestamp)}</p>
                                                    <p className="like-element light-text-very-small grey custom-pl-10">♡</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                    <div className="last-element-scrollbar w-100 custom-pt-30" ref={bottomRef} ></div>
                                    </Scrollbars>
                                    <div className="w-100 scrollbottom-btn position-absolute cutom-pt-20">
                                        <button className="custom-btn square-bnt" onClick={() => handlerScrollBottom() } >
                                            <img className="custom-mb-5 arrow-down" src={arrowDown} alt="scroll to bottom" />
                                        </button>
                                    </div>
                                </div>
                                
                                <div className="textareaChat custom-mt-10  w-100">
                                { !userCanWrite || pubNubChatConnector === null ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="line custom-mr-20"></div>
                                        <p className="light-text-very-small">ONLY TOKEN HOLDERS CAN POST MESSAGES</p>
                                        <div className="line dx custom-ml-20"></div>
                                    </div>
                                ) : (
                                    <div className="text-area-container d-flex align-items-center justify-content-center">
                                        <input autoComplete="off" type="textarea" className="w-100" placeholder="Post a message" name="textValue" value={message} onChange={ handleChange }/>
                                        <div className="text-center custom-ml-20">
                                            <button className="custom-btn small-bnt w100" onClick={() => handlerSendMessage()}>
                                                <p className="btn-text">Send</p>
                                            </button>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          </Page>
        </>
      )}
    </>
  );
};

export default CommunityChat;
