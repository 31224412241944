import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/createContext";
import { copy, edit, next, share, received, sent, iconEth, iconUsdc, iconMatic, avatar, chat, customers, bellaToken } from "@/config/svg";
import LoaderAnimation from "@/components/Lottie/loader";
import PerfectScrollbar from 'react-perfect-scrollbar';
import LazyLoad from "react-lazyload";
import FadeIn from "react-fade-in";
import Page from "@/layouts/Page";
import routes from "@/config/routes";
import { useNavigate } from "react-router-dom";
import { userProfile, privateChannels, getTokens, getNfts, getTransactions } from '@/services/index';
import { clipString, checkSameString, timestampToDate } from "@/helper/common";
import 'react-perfect-scrollbar/dist/css/styles.css';
const Profile: React.FC = () => {

    let navigate = useNavigate();
    const [selectedCommunity, setSelectedCommunityFeed] = useState<any>(null);
    const { state, walletConnector, checkWalletConnection, connect, isAuthenticate, disconnect,
        selectCommunityFeed, addCommunityFeedList, addUserChannelTokenList, resetState, addUserProfile, addPrivateChannelsList, addTokensList, addTransactionsList, addNftList, resetError, setError } = useContext(AppContext);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [hasCommunities, setHasCommunities] = useState(false);
    const [userPrivateChannels, setUserPrivateChannels] = useState<any>([]);
    const [userTokens, setUserTokens] = useState<any>([]);
    const [userNfts, setUserNfts] = useState<any>([]);
    const [userTransactions, setUserTransactions] = useState<any>([]);
    const [profile, setProfile] = useState<any>([]);
    const [loader, stopAnimation] = useState(false);
    const { pubNubChatConnector, pubnubDestroy } = useContext(AppContext);
    useEffect(() => {

        document.body.className = "profile-page";
        return () => {
            document.body.className = "";
        };
    }, []);

    useEffect(() => {
        //check if I have a wallet connection
        if (walletConnector === null) {
            checkWalletConnection('boolean').then(async (isConnected) => {
                if (isConnected) {
                    await connect();
                } else {
                    // guest user - silent is golden
                }
            })
        }
        //if I have a wallet connection - logged user
        if (walletConnector !== null) {
            (async function () {
                if (await isAuthenticate()) {
                    setIsUserAuthenticated(true);
                }
                else {
                    console.log("disconnect wallet connect session");
                    disconnect(); //disconnect wallet connect session
                }
            })();
        }
    });

    /* profile */
    useEffect(() => {
        if (isUserAuthenticated) {
            //console.log('here');
            // console.log(state);
            if (profile && profile.length === 0) {
                // console.log('bubu1');
                if (state?.userProfile && state?.userProfile?.length > 0) {
                    //  console.log("bubu");
                    setProfile(state?.userProfile);
                    stopAnimation(true);
                } else {
                    const bellaUserId = localStorage.getItem("bellaUserId");
                    const accessToken = localStorage.getItem("accessToken");
                    if (bellaUserId && bellaUserId !== "" && accessToken && accessToken !== "") {
                        userProfile(state?.bellaUser?.bellaUserId, state?.bellaUser?.accessToken)
                            .then(async (res: any) => {
                                if (res?.response?.status === 401) {
                                    disconnect();
                                    window.location.reload();
                                }
                                else if (res?.name === 'AxiosError') {
                                    const error = {
                                        visible: true,
                                        text: res?.message,
                                    }
                                    setError(error);
                                }
                                else if (res?.data?.payload) {

                                    addUserProfile(res.data.payload);
                                    setProfile(res.data.payload);
                                    stopAnimation(true);
                                    resetError(null);
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else {
                        console.log("ERROR DATA NOT FOUND");
                    }
                }
            }

        }

    }, [isUserAuthenticated, userProfile, addUserProfile, setProfile])


    /* private channels */
    useEffect(() => {
        if (isUserAuthenticated) {
            // end condition
            if (userPrivateChannels && userPrivateChannels.length === 0) {

                // check if I have the info saved on context
                if (state?.privateChannelsList && state?.privateChannelsList?.length > 0) {
                    setUserPrivateChannels(state?.privateChannelsList);
                    //   stopAnimation(true);

                } else {

                    const bellaUserId = localStorage.getItem("bellaUserId");
                    const accessToken = localStorage.getItem("accessToken");

                    if (bellaUserId && bellaUserId !== "" && accessToken && accessToken !== "") {
                        // guest user logged - call community services
                        privateChannels(state?.bellaUser?.bellaUserId, state?.bellaUser?.accessToken)
                            .then(async (res: any) => {
                                if (res?.response?.status === 401) {
                                    disconnect();
                                    window.location.reload();
                                }
                                else if (res?.name === 'AxiosError') {
                                    const error = {
                                        visible: true,
                                        text: res?.message,
                                    }
                                    setError(error);
                                }
                                else if (res?.data?.results) {
                                    addPrivateChannelsList(res.data.results);
                                    setUserPrivateChannels(res.data.results);
                                    resetError(null);
                                } else {
                                    //  console.log("Nope");
                                }
                                //  console.log(res.data);
                            })
                            .catch((error) => {
                                // ERROR
                                console.log(error);
                            });
                    } else {
                        console.log("ERROR DATA NOT FOUND");
                    }
                }

            }

            if (userPrivateChannels.length > 0) {
                setHasCommunities(true);
            }
        }
    }, [isUserAuthenticated, privateChannels, addPrivateChannelsList, setUserPrivateChannels])

    /* tokens */
    useEffect(() => {
        if (isUserAuthenticated) {
            if (userTokens && userTokens.length === 0) {
                if (state?.tokensList && state?.tokensList?.length > 0) {
                    setUserTokens(state?.tokensList);
                } else {
                    const bellaUserId = localStorage.getItem("bellaUserId");
                    const accessToken = localStorage.getItem("accessToken");
                    if (bellaUserId && bellaUserId !== "" && accessToken && accessToken !== "") {
                        getTokens(state?.bellaUser?.bellaUserId, state?.bellaUser?.accessToken)
                            .then(async (res: any) => {
                                if (res?.response?.status === 401) {
                                    disconnect();
                                    window.location.reload();
                                }
                                else if (res?.name === 'AxiosError') {
                                    const error = {
                                        visible: true,
                                        text: res?.message,
                                    }
                                    setError(error);
                                }
                                else
                                    if (res?.data?.payload) {
                                        addTokensList(res.data.payload);
                                        setUserTokens(res.data.payload);
                                        resetError(null);
                                    }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else {
                        console.log("ERROR DATA NOT FOUND");
                    }
                }
            }
        }

    }, [isUserAuthenticated, addTokensList, setUserTokens, getTokens])

    /* transactions */
    useEffect(() => {
        if (isUserAuthenticated) {
            if (userTransactions && userTransactions.length === 0) {
                if (state?.transactionsList && state?.transactionsList?.length > 0) {
                    setUserTransactions(state?.transactionsList);
                    //   stopAnimation(true);
                } else {
                    const bellaUserId = localStorage.getItem("bellaUserId");
                    const accessToken = localStorage.getItem("accessToken");

                    if (bellaUserId && bellaUserId !== "" && accessToken && accessToken !== "") {
                        // guest user logged - call community services
                        getTransactions(state?.bellaUser?.bellaUserId, state?.bellaUser?.accessToken)
                            .then(async (res: any) => {
                                if (res?.response?.status === 401) {
                                    disconnect();
                                    window.location.reload();
                                }
                                else if (res?.name === 'AxiosError') {
                                    const error = {
                                        visible: true,
                                        text: res?.message,
                                    }
                                    setError(error);
                                }
                                else
                                    if (res?.data?.payload) {
                                        addTransactionsList(res.data.payload);
                                        setUserTransactions(res.data.payload);
                                        resetError(null);
                                    } else {
                                        //console.log("Nope transactions");
                                    }
                                //   console.log(res.data);
                            })
                            .catch((error) => {
                                // ERROR
                                console.log(error);
                            });
                    } else {
                        console.log("ERROR DATA NOT FOUND");
                    }
                }

            } else {
                // console.log("hre transactions");
            }


        } else {
            // console.log("nein transactions")
        }
    }, [isUserAuthenticated, getTransactions, addTransactionsList, setUserTransactions])

    /*nfts */
    useEffect(() => {
        if (isUserAuthenticated) {
            // end condition
            if (userNfts && userNfts.length === 0) {

                // check if I have the info saved on context
                if (state?.nftList && state?.nftList?.length > 0) {
                    setUserNfts(state?.nftList);
                    //   stopAnimation(true);
                } else {
                    const bellaUserId = localStorage.getItem("bellaUserId");
                    const accessToken = localStorage.getItem("accessToken");
                    if (bellaUserId && bellaUserId !== "" && accessToken && accessToken !== "") {
                        // guest user logged - call community services
                        getNfts(state?.bellaUser?.bellaUserId, state?.bellaUser?.accessToken)
                            .then(async (res: any) => {
                                if (res?.response?.status === 401) {
                                    disconnect();
                                    window.location.reload();
                                }
                                else if (res?.name === 'AxiosError') {
                                    const error = {
                                        visible: true,
                                        text: res?.message,
                                    }
                                    setError(error);
                                }
                                else
                                    if (res?.data?.payload) {
                                        addNftList(res.data.payload);
                                        setUserNfts(res.data.payload);
                                        resetError(null);
                                    } else {
                                        //console.log("Nope nft");
                                    }
                                //console.log(res.data);
                            })
                            .catch((error) => {
                                // ERROR
                                // console.log(error);
                            });
                    } else {
                        console.log("ERROR DATA NOT FOUND");
                    }

                }
            } else {
                // console.log("hre nft");
            }
            // console.log('userNfts');
            ///  console.log(userNfts);
            /*  if (userNfts?.records && userNfts?.records.length > 0) {
                  setHasNft(true);
              }*/

        } else {
            // console.log("nein nft")
        }
    }, [isUserAuthenticated, getNfts, addNftList, setUserNfts])

    const selectCommunity = async (item: any) => {
        console.log("select comm");
        if (item) {
            setSelectedCommunityFeed(item.communityId);
            selectCommunityFeed(item, true);
            navigate(routes.communitySelectedFeed);
        }
    };



    const handleLogOut = async () => {
        if (pubNubChatConnector !== null) {
            pubnubDestroy();
        }
        disconnect();
        resetState();

        setShowPopup(true);
        setTimeout(() => {
            navigate(routes.root);
        }, 4000);

    };

    return (
        <>

            <Page>
                {!isUserAuthenticated ? (
                    <section className=" h-100 d-flex flex-column flex-grow-1">
                        <div className="align-items-center container d-flex flex-column flex-grow-1 h-100 justify-content-end">

                            <div className="absolute-text text-center">
                                <h1 className="regular-text grey custom-mb-10 ">Wallet not connected yet</h1>

                            </div>
                            <button className="custom-btn big-btn custom-mb-30" onClick={() => navigate(routes.wallet)}>
                                <p className="btn-text">Connect your wallet</p>
                            </button>

                        </div>

                    </section>
                ) : (

                    <>
                        {!loader ? (
                            <div className="container h-100 d-flex justify-content-center align-items-center">
                                <LoaderAnimation height={100} width={100} />
                            </div>
                        ) : (
                            <>

                                <div className=" h-100">
                                    <LazyLoad height={200} once >
                                        <FadeIn transitionDuration={800}>
                                            <section className="custom-pr-20 custom-pl-20">
                                                <div className="d-flex justify-content-between custom-pt-20 custom-pb-10 align-items-center">
                                                    <h1 className="light-text-large ">{
                                                        (profile?.alias) ? profile.alias : profile?.walletAddress ? clipString(profile.walletAddress) : "n/a"

                                                    }</h1>
                                                    {/* <img className="" src={edit} alt="edit" /> */}
                                                </div>
                                                <div className="d-flex justify-content-between custom-pt-10 custom-pb-10">
                                                    <div className="d-flex flex-grow-1">
                                                        <div className="account-icon">
                                                            <img className="" src={(profile?.avatarUrl) ? profile.avatarUrl : avatar} alt="edit" />
                                                        </div>


                                                        <div className="d-flex flex-column custom-pl-10">
                                                            <p className="underline custom-mb-10">{(profile?.walletAddress) ? clipString(profile.walletAddress) : "n/a"}</p>
                                                            {/*    <p className="grey regular-text-small ">Ethereum Mainnet</p> */}
                                                        </div>
                                                    </div>
                                                    <div className="">

                                                    </div>

                                                </div>
                                                {/*
                                <button className="custom-btn full-width-btn custom-mb-30 custom-mt-40" onClick={() => navigate(routes.profile)}>
                                    <p className="btn-text">Refresh your assets</p>
                                </button>
                                */}
                                            </section>
                                        </FadeIn>
                                    </LazyLoad>
                                    <LazyLoad height={200} once >
                                        <FadeIn transitionDuration={800}>
                                            <section className="custom-pb-20 custom-mt-30">
                                                <h2 className="medium-text custom-pb-20 custom-pl-20">Private channels you joined</h2>
                                                {hasCommunities ? (
                                                    <PerfectScrollbar className="custom-scrollbar">
                                                        <ul className="custom-pr-20 custom-pl-20 d-flex private-channels-list nowrap custom-pb-15">
                                                            {userPrivateChannels.length > 0 &&
                                                                userPrivateChannels.map((community: any, index: number) => (
                                                                    <li key={community.communityId} className={community.communityId + " flex-shrink-0 community-body position-relative item "}
                                                                        style={{ backgroundImage: `url(${community?.iconUrl})` }} onClick={() => selectCommunity(community)}>
                                                                        <div key={community.communityId} className='box-info position-absolute w-100 d-flex align-items-center justify-content-between'   >
                                                                            <div className='custom-ml-10 d-flex align-items-center'>
                                                                                <img src={customers} alt="customers" />
                                                                                <p className="custom-ml-5">{community?.numberOfUsers}</p>
                                                                            </div>
                                                                            <div className='custom-mr-10 d-flex align-items-center'>
                                                                                <img src={chat} alt="customers" />
                                                                                <p className="custom-ml-5">{community?.numberOfMsgs}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="position-absolute romboblock-wrapper d-flex flex-columns justify-content-center align-items-center">
                                                                            <div className="romboblock position-relative"
                                                                                style={{ backgroundImage: `url(${bellaToken})` }} >
                                                                                <img className="position-absolute" src={community?.iconUrl} alt="icon" />
                                                                            </div>
                                                                        </div>

                                                                    </li>

                                                                ))}

                                                        </ul>
                                                    </PerfectScrollbar>
                                                ) : (

                                                    <div className="custom-pr-20 custom-pl-20 d-flex private-channels-list nowrap custom-pb-15 is-placeholder">
                                                        <div className="item placeholder-item flex-shrink-0"></div>
                                                        <div className="item placeholder-item flex-shrink-0"></div>
                                                        <div className="item placeholder-item flex-shrink-0"></div>
                                                        <div className="item placeholder-item flex-shrink-0"></div>
                                                        <div className="text-center position-absolute btn-wrapper d-flex flex-column justify-content-center align-items-center">
                                                            <button className="custom-btn small-bnt" onClick={() => navigate(routes.root)}>
                                                                <p className="btn-text">Join your favourite!</p>
                                                            </button>
                                                        </div>

                                                    </div>

                                                )}


                                            </section>
                                        </FadeIn>
                                    </LazyLoad>
                                    <LazyLoad height={200} once >
                                        <FadeIn transitionDuration={800}>
                                            <section className="custom-pr-20 custom-pl-20">
                                                <h2 className="medium-text custom-pb-10">Your connected assets</h2>
                                                <div className="rainbow-separator"></div>


                                                <ul className="custom-pt-10 custom-pb-10">

                                                    {userTokens?.balances?.length > 0 ? (

                                                        userTokens.balances.map((token: any, index: number) => (

                                                            <li key={index.toString()} className="d-flex justify-content-between custom-pb-30">
                                                                <p className="d-flex align-items-center custom-pr-20 flex-grow-1">
                                                                    <img className="custom-mr-5 currency-icon" src={token?.logoUrl} alt="" />{token?.symbol}
                                                                </p>
                                                                <p className="text-right">{token?.amountDisplay}</p>
                                                            </li>

                                                        ))

                                                    ) : (
                                                        <li className="d-flex justify-content-between custom-pb-30">
                                                            <p className="d-flex align-items-center custom-pr-20 flex-grow-1">

                                                            </p>
                                                            <p className="text-right">0.000000</p>
                                                        </li>
                                                    )}

                                                </ul>



                                            </section>

                                        </FadeIn>
                                    </LazyLoad>
                                    <LazyLoad height={200} once >
                                        <FadeIn transitionDuration={800}>
                                            <section className="custom-pb-20">

                                                <h2 className="medium-text custom-pb-10 custom-pl-20">Your NFTs</h2>
                                                {userNfts?.records && userNfts?.records.length > 0 ? (

                                                    <PerfectScrollbar className="custom-scrollbar">
                                                        <ul className="custom-pt-10 custom-pr-20 custom-pl-20 d-flex nfts-list nowrap custom-pb-15">
                                                            {
                                                                userNfts.records.map((item: any, index: number) => (

                                                                    <li key={index.toString()} className="item flex-shrink-0" style={{ backgroundImage: `url(${item?.imageUrl})` }} >
                                                                        {item?.balance > 1 ? (
                                                                            <div className="nftbalancewrapper"><div className="nftbalance text-center medium-text-small">{item?.balance}</div></div>
                                                                        ) :
                                                                            <></>
                                                                        }


                                                                    </li>

                                                                ))}

                                                        </ul>
                                                    </PerfectScrollbar>) : (
                                                    <div className="custom-pr-20 custom-pt-10 custom-pl-20 d-flex nfts-list nowrap custom-pb-15 is-placeholder">
                                                        <div className="item placeholder-item flex-shrink-0"></div>
                                                        <div className="item placeholder-item flex-shrink-0"></div>
                                                        <div className="item placeholder-item flex-shrink-0"></div>
                                                        <div className="item placeholder-item flex-shrink-0"></div>
                                                        <p className="grey position-absolute custom-pb-15 custom-pl-20">You don't have any NFTs yet</p>
                                                    </div>
                                                )}

                                            </section>

                                        </FadeIn>
                                    </LazyLoad>
                                    <LazyLoad height={200} once >
                                        <FadeIn transitionDuration={800}>
                                            <section className="custom-pr-20 custom-pl-20">
                                                <h2 className="medium-text custom-pb-10">Latest Transactions</h2>
                                                <div className="rainbow-separator"></div>
                                                {userTransactions?.history && userTransactions?.history.length > 0 ? (

                                                    <ul className="custom-pt-10 custom-pb-10">


                                                        {
                                                            userTransactions.history.map((transaction: any, index: number) => (
                                                                <li key={index.toString()} className="d-flex justify-content-between custom-pb-30 align-items-center">

                                                                    <div className="d-flex flex-column custom-pr-20 flex-grow-1">
                                                                        <p className="d-flex align-items-center custom-mb-5">
                                                                            <img className="custom-mr-5" src={checkSameString(transaction?.fromAddress, profile?.walletAddress) ? sent : received} alt="" /><span className="regular-text gradient d-inline-block">
                                                                                {(checkSameString(transaction?.fromAddress, profile?.walletAddress)) ? "Send " : "Receive"}</span>
                                                                            <span className="custom-ml-15 confirmation medium-text-xs">Confirmed</span>
                                                                        </p>
                                                                        <p className="regular-text">
                                                                            {checkSameString(transaction?.fromAddress, profile?.walletAddress) ? "To " + clipString(transaction?.toAddress) : "From " + clipString(transaction.fromAddress)}
                                                                        </p>
                                                                        <p className="regular-text-small grey">{transaction?.transactionTimestamp ? timestampToDate(transaction.transactionTimestamp) : ""}</p>
                                                                    </div>

                                                                    <p className="text-right"><img className="currency-icon-mini custom-mr-5" src={transaction?.logoUrl} alt="" />   {transaction?.amountDisplay}</p>
                                                                </li>

                                                            ))}


                                                    </ul>

                                                ) : (
                                                    <p className="grey custom-pl-20 custom-mb-82 custom-mt-82">You don't have any transactions yet</p>
                                                )}

                                            </section>
                                        </FadeIn>
                                    </LazyLoad>
                                    <LazyLoad height={200} once >
                                        <FadeIn transitionDuration={800}>
                                            <section>
                                                <div className="rainbow-separator"></div>
                                                <ul>
                                                    <li>
                                                        <button className="link-btn" onClick={() => navigate(routes.deleteAccount)}>
                                                            <p className="medium-text">Delete Account</p>
                                                            <img src={next} alt="" />
                                                        </button>

                                                    </li>
                                                    <li>
                                                        <button className="link-btn" onClick={() => handleLogOut()}>
                                                            <p className="medium-text">Logout</p>
                                                            <img src={next} alt="" />
                                                        </button>

                                                    </li>
                                                </ul>
                                                <div className="rainbow-separator"></div>
                                            </section>

                                        </FadeIn>
                                    </LazyLoad>

                                </div>

                            </>
                        )}
                    </>


                )}
                {showPopup ?
                    <FadeIn transitionDuration={600}>
                        <div className="popup-overlay">
                            <div className="popup-inner">
                                <div className="popup-content text-center">
                                    <p className="medium-text custom-pb-20 custom-pt-20">You are now successfuly logged out!</p>

                                </div>
                            </div>
                        </div>
                    </FadeIn>
                    : <></>}



            </Page>
        </>
    )
};

export default Profile;


