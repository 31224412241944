import React, { useEffect, useState, useContext } from "react";
import Page from "@/layouts/Page";
import RailPinkAnimation from "@/components/Lottie/railpink";
import { AppContext } from "@/context/createContext";
import { useNavigate } from "react-router-dom";
import routes from "@/config/routes";

const Wallet: React.FC = () => {

    let navigate = useNavigate();
    const [accountsID, setAccountsID] = useState<any>(false);
    const { state, connect, walletConnector, disconnect } = useContext(AppContext);

    let connectorCount = 0;
    useEffect(() => {

        if (walletConnector === null && connectorCount === 0) {
            connect();
            setAccountsID(false)
            connectorCount++;
        }
        if (walletConnector !== null && walletConnector.connected)
            navigate(routes.onboarding);
        if (state?.walletConnectInfo && state?.walletConnectInfo !== null && state.walletConnectInfo?.accounts)
            setAccountsID(state.walletConnectInfo?.accounts)

        document.body.className = "wallet-page";
        return () => {
            document.body.className = "";
        };

    }, [connect, connectorCount, navigate, state.walletConnectInfo, walletConnector]);

    return (
        <>
            <Page>
                <section className="wallet-section h-100 custom-mt-20">
                    <div className="container h-100">
                        <div className="row h-100 d-flex flex-column">
                            <div className="d-flex flex-column justify-content-center align-items-center custom-mt-60 text-center">
                                <h1 className="rainbow-title-page custom-mb-82">WALLET TIME!</h1>

                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center  text-center">
                                {
                                    accountsID ? <>
                                        <span>{accountsID}</span><br />
                                        <button className="custom-btn big-btn custom-mb-30" onClick={disconnect}>
                                            <p className="btn-text">Disconnect</p>
                                        </button>
                                    </> :
                                        <div>
                                            <button className="custom-btn small-bnt " onClick={connect}>
                                                <p className="btn-text">Connect your wallet</p>
                                            </button>
                                            <button className="black-btn super-small-bnt custom-mt-15" onClick={() => navigate(routes.root)}>
                                                <p className="btn-text ">Not now</p>
                                            </button>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="lottie-rail-wrapper">
                        <RailPinkAnimation />
                    </div>
                </section>
            </Page>
        </>
    )
}

export default Wallet;