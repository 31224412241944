import React from 'react';
import Lottie from "react-lottie";

import loaderAnimation_img0 from "../../assets/lottie/Loader/images/logoLoader_0.png";
import loaderAnimation_img1 from "../../assets/lottie/Loader/images/logoLoader_1.png";

const Loader: React.FC<{height: number, width: number}> = ({
  height,
  width
}) => {

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: {"v":"5.6.5","fr":30,"ip":0,"op":100,"w":220,"h":220,"nm":"LoaderLogo","ddd":0,"assets":[{"id":"logoLoader_0","w":305,"h":271,"u":"","p":loaderAnimation_img0,"e":0},{"id":"logoLoader_1","w":371,"h":337,"u":"","p":loaderAnimation_img1,"e":0}],"layers":[{"ddd":0,"ind":1,"ty":2,"nm":"LOGO.png","cl":"png","refId":"logoLoader_0","sr":1,"ks":{"o":{"a":0,"k":100,"ix":11},"r":{"a":1,"k":[{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":0,"s":[0]},{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":45,"s":[180]},{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":50,"s":[180]},{"t":96,"s":[360]}],"ix":10},"p":{"a":0,"k":[110,110,0],"ix":2},"a":{"a":0,"k":[152.5,135.5,0],"ix":1},"s":{"a":0,"k":[60,60,100],"ix":6}},"ao":0,"ip":0,"op":584,"st":-45,"bm":0},{"ddd":0,"ind":2,"ty":2,"nm":"LOGO2.png","cl":"png","refId":"logoLoader_1","sr":1,"ks":{"o":{"a":1,"k":[{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":0,"s":[100]},{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":15,"s":[0]},{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":30,"s":[0]},{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":45,"s":[100]},{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":50,"s":[100]},{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":65,"s":[0]},{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":81,"s":[0]},{"t":96,"s":[100]}],"ix":11},"r":{"a":1,"k":[{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":0,"s":[0]},{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":45,"s":[180]},{"i":{"x":[0.667],"y":[1]},"o":{"x":[0.333],"y":[0]},"t":50,"s":[180]},{"t":96,"s":[360]}],"ix":10},"p":{"a":0,"k":[110,110,0],"ix":2},"a":{"a":0,"k":[185.5,168.5,0],"ix":1},"s":{"a":0,"k":[60,60,100],"ix":6}},"ao":0,"ip":0,"op":584,"st":-45,"bm":0}],"markers":[]},
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        <Lottie
          options={defaultOptions}
          height={height}
          width={width}
          style={{
            overflow: "hidden"
          }}
        />
      </>
    )
};

export default Loader;
