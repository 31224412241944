/* eslint-disable react-hooks/exhaustive-deps */
import Header from "./Header";
import Footer from "./Footer";
import { ProviderProps } from "../interface/type";
import { useContext, useEffect, useState } from "react";
import { isLogin } from "@/helper/common";
import { AppContext } from "@/context/createContext";
import LoaderAnimation from "@/components/Lottie/loader";

const Page = ({ children }: ProviderProps) => {

  const { state } = useContext(AppContext);
  const  [loader, stopAnimation] = useState<boolean>(false);
  const [isLoggedIn, setIsLogin] = useState(false);
  const [isWaitingContent, setIsWaitingContent] = useState(false);

  useEffect(() => {
    if (state?.bellaUser?.bellaUserId) {
      // guest user logged
      setIsLogin(isLogin(state?.bellaUser?.bellaUserId));
      stopAnimation(true);
    } 
  }, []);

  useEffect(() => {

    const waitingResponse = localStorage.getItem("waiting-response");
    if (waitingResponse && waitingResponse === "true") {
      setIsWaitingContent(true);
    }
    else {
      setIsWaitingContent(false);
    }
  });

  return (
    <>
      {((loader && !isLoggedIn) || isWaitingContent) ? (
        <section className="loader-section flex-grow-1 d-flex flex-column">
          <div className="container flex-grow-1 d-flex flex-column">
            <div className=" flex-grow-1 d-flex flex-column">
              <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
                <LoaderAnimation height={100} width={100} />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          <div className="master-container">
            <Header />
              <div className="master-container-section">{children}</div>
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default Page;