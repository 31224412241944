

export default function Footer() {

  const basepath = process.env.REACT_APP_WEBSITE_BASE_URL

  //console.log(basepath);

  return (
    <div className='container-fluid footer-container'>
      <div className="footer-block">
        <div className='inner-footer custom-pt-20 custom-pb-20 d-flex align-items-center justify-content-center'>
          <div className='copyright'>© 2022 Bella. All rights reserved.</div>
          <div className='privacy'>
            <a href={`${basepath}/privacy/pdf/Bella-Privacy-Policy.pdf`} target='_blank' className='regular-text-small'>Privacy</a>
          </div>
          <div className=''>
            <a href={`${basepath}/terms/pdf/Bella-Terms-of-Use.pdf`} target='_blank' className='regular-text-small'>Legal</a>
          </div>
        </div>
      </div>
    </div>
  )
}