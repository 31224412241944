import Web3 from "web3";
import Abicode from '@/config/json/ContractABI.json'
import { ethers } from "ethers";
import { useNavigate, useParams } from "react-router-dom";

console.log({Abicode});
class BellWeb3 {
    private web3;
    private fromAddress='';
    private toAddress='';
    private myContract:any = false;
    private accessTokenManager:any = false;
    private nonce:any = '';
    
    constructor(){
        // this.web3 = new Web3('https://goerli.infura.io/v3/4406c3acf862426c83991f1752c46dd8');
        const rpc_url = process.env.REACT_APP_RPC_URL_ETHEREUM ?? ''; 
        this.web3 = new Web3(rpc_url);
    }

    updateNetwork = (chain:string,network:string , updateContract:boolean = true) =>{
        let rpc_url = '';
        if(chain === 'ETHEREUM'){
            rpc_url = process.env.REACT_APP_RPC_URL_ETHEREUM ?? ''; 
            this.accessTokenManager = process.env.REACT_APP_ACCESS_TOKEN_MANAGER_ADDRESS_ETHEREUM ?? '';
        }       
        else if(chain === 'POLYGON'){
            rpc_url = process.env.REACT_APP_RPC_URL_POLYGON ?? '';
            this.accessTokenManager = process.env.REACT_APP_ACCESS_TOKEN_MANAGER_ADDRESS_POLYGON ?? '';
        }

        console.log({rpc_url});       

        this.web3.setProvider(rpc_url);

        this.setToAddress(this.accessTokenManager)

        if(updateContract)
        this.updateContract();

       /*  if(network === 'GOERLI'){
            this.setToAddress(process.env.REACT_APP_SC_ACCESS_TOKEN_MANAGER_ADDRESS_GOERLI as string)
            this.accessTokenManager = process.env.REACT_APP_SC_ACCESS_TOKEN_MANAGER_ADDRESS_GOERLI;
        }
        if(network === 'MUMBAI'){
            this.setToAddress(process.env.REACT_APP_SC_ACCESS_TOKEN_MANAGER_ADDRESS_MUMBAI as string)
            this.accessTokenManager = process.env.REACT_APP_SC_ACCESS_TOKEN_MANAGER_ADDRESS_MUMBAI;
        } */

        // old code 
        /* let rpc_url = '';
        if(chain === 'ETHEREUM' && network === 'MAINNET' )
            rpc_url = 'https://mainnet.infura.io/v3/4406c3acf862426c83991f1752c46dd8';
        else if(chain === 'ETHEREUM' && network === 'GOERLI' )
            rpc_url = 'https://goerli.infura.io/v3/4406c3acf862426c83991f1752c46dd8';
        else if(chain === 'POLYGON' && network === 'MAINNET' )
            rpc_url = 'https://matic-mainnet-archive-rpc.bwarelabs.com';
        else if(chain === 'POLYGON' && network === 'MUMBAI' )
            rpc_url = 'https://matic-mumbai.chainstacklabs.com';

        console.log({rpc_url});
        

        this.web3.setProvider(rpc_url);

        if(network === 'GOERLI'){
            this.setToAddress(process.env.REACT_APP_SC_ACCESS_TOKEN_MANAGER_ADDRESS_GOERLI as string)
            this.accessTokenManager = process.env.REACT_APP_SC_ACCESS_TOKEN_MANAGER_ADDRESS_GOERLI;
        }
        if(network === 'MUMBAI'){
            this.setToAddress(process.env.REACT_APP_SC_ACCESS_TOKEN_MANAGER_ADDRESS_MUMBAI as string)
            this.accessTokenManager = process.env.REACT_APP_SC_ACCESS_TOKEN_MANAGER_ADDRESS_MUMBAI;
        } 

        if(updateContract)
        this.updateContract();*/
    }

    updateContract = () =>{
        const jsonInterface:any = Abicode;
        if(this.accessTokenManager && this.fromAddress){

            this.myContract = new this.web3.eth.Contract(jsonInterface, this.accessTokenManager);
            console.log({myContract:this.myContract});
            console.log({accessTokenManager:this.accessTokenManager});
        }
    }

    getData = (channelId:string) => {
        return this.myContract.methods.purchase(channelId).encodeABI();
    }

    getGasEstimation = async (channelId:string):Promise<any> => {
        
        let gasAmount:any = 0.0;   
        let gasFee:any = 0.0;   
        let gasFeeEth:any = 0.0;

        
        const gasPrice: any = await this.web3.eth.getGasPrice();
        console.log({fromAddress:this.fromAddress,toAddress:this.toAddress});
        
        if(this.fromAddress && this.toAddress)
        gasAmount = await this.getGasAmount(channelId);
        if(gasAmount?.message){
            return gasAmount?.message;
        }
        gasFee = gasPrice * gasAmount;
        gasFeeEth = this.web3.utils.fromWei(gasFee.toString(), 'ether');

        const gasEstimationDebug = {fromAddress:this.fromAddress,toAddress:this.toAddress,gasPrice,gasAmount,gasFeeEth}        
        console.log({gasEstimationDebug});  

        return gasFeeEth;
    }

    getFinalPrice = async (channelId:string):Promise<string> => {
        let finalPrice = '';
        await this.myContract.methods.idToAccessTokenItem(channelId).call({from:this.fromAddress},(error:any,result:any)=>{
            if(error)
            console.log({error});
            if(result){
                finalPrice = result?.finalPrice;
                console.log({result});
            }
            
        })

        return finalPrice;
    }

    getGasAmount = async (channelId:string):Promise<number> => {
        let gasAmount:any = 0;

        const finalPrice = await this.getFinalPrice(channelId);
        console.log({finalPrice});        
        
        const params = {
            to: this.toAddress,
            from: this.fromAddress,
            // value: parseInt(finalPrice) 
            value: parseInt(finalPrice) 
          };
          
        const newPram = {...params};
        console.log({params,newPram});          

        try {
            gasAmount = await this.myContract.methods.purchase(channelId).estimateGas(params);             
        } catch (error:any) {
            console.log({error}); 
            return error;
                       
        }         
         
        return gasAmount
    }

    setNonce = (nonce:number):void =>{
        this.nonce = nonce;
    }
    setToAddress = (toAddress:string):void =>{
        this.toAddress = toAddress;
    }

    getToAddress = () =>{
        return this.toAddress;
    }

    setFromAddress = (fromAddress:string):void =>{
        this.fromAddress = fromAddress;
    }
    
    getFromAddress = ():string =>{
        return this.fromAddress;
    }

    purchaseData = (channelId:string) =>{
        return this.myContract.methods.purchase(channelId).encodeABI();        
    }

    sendTransaction = async (walletConnector:any, channelId:string):Promise<any> => {

        const finalPrice = await this.getFinalPrice(channelId);
        
        // Draft transaction
        const tx = {
            from: this.fromAddress, // Required
            to: this.toAddress, // Required (for non contract deployments)
            value: finalPrice,
            data: this.myContract.methods.purchase(channelId).encodeABI(), // Required
        };
        const sendTransactionDebug = {
            tx,
            walletConnector
        }
        console.log({sendTransactionDebug});
        // Send transaction
        // try{
        //     const transaction = await walletConnector.signTransaction(tx);
        // } catch(error){
        //     console.log({error});
        // }
        
           return await walletConnector.sendTransaction(tx);  
        
       
            
    }

}


export default BellWeb3;